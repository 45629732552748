import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { CollapsibleContainerBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import BaseInputProps from "@prop-types/BaseInputProps";
import CollapsibleContainerProps from "@prop-types/CollapsibleContainerProps";
import JSXProps from "@prop-types/JSXProps";
import BotAwareComponent from "./BotAwareComponent";

class CollapsibleContainer extends BotAwareComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: null,
      collapsed: props.collapsed,
      dataset: {}
    };

    this.handleCollapse = this.handleCollapse.bind(this);
  }

  getLabel(value, props) {
    const text = value; //+ (!!this.props.unit ? " " + this.props.unit : "");

    const dataset = Object.keys(this.state.dataset).reduce(
      (carry, key) =>
        Object.assign(carry, { ["data-" + key]: this.state.dataset[key] }),
      {}
    );

    return (
      <React.Fragment>
        {props.beforeTitle}
        <label
          className={props.className}
          htmlFor={props.labelable ? props.htmlFor || this.props.id : null}
          {...dataset}
        >
          {text}
        </label>
        {props.afterTitle}
      </React.Fragment>
    );
  }

  /**
   * @description Get the container title
   * @returns {String}
   * @memberof CollapsibleContainer
   */
  getTitle() {
    return this.props.title;
  }

  /**
   * @description Get the header/title buttons
   * @returns {Array} Returns an array of props for each button
   * @memberof CollapsibleContainer
   */
  getHeaderButtons() {
    return [];
  }

  handleCollapse(e) {
    this.setState(
      state => {
        return { ...state, collapsed: !state.collapsed };
      },
      () =>
        this.props.onChange ? this.props.onChange(this.state.collapsed) : {}
    );
  }

  render(children) {
    if (false === !!children) {
      children = this.props.children;
    }

    const icon = this.state.collapsed ? "chevron-right" : "chevron-up";

    const onToggle = this.props.collapsible ? this.handleCollapse : null;

    const collapseToggler = this.props.collapsible ? (
      <Col
        xs={1}
        md={1}
        className={getComponentClassName(
          CollapsibleContainerBS,
          null,
          "m-0 p-0 text-right"
        )}
      >
        <FontAwesomeIcon
          icon={icon}
          className="cursor-pointer text-secondary"
        />
      </Col>
    ) : null;

    const buttons = this.getHeaderButtons().map((props, i) => (
      <Col
        key={i}
        xs={1}
        md={1}
        className="m-0 p-0 cursor-pointer text-right"
        onClick={e => e.stopPropagation()}
      >
        <FontAwesomeIcon {...props} />
      </Col>
    ));

    const colspan = 12 - +Boolean(collapseToggler) - buttons.length;

    const _title = this.getTitle();

    const title =
      _title || collapseToggler ? (
        <Row
          onClick={onToggle}
          className={getComponentClassName(
            CollapsibleContainerBS,
            "toggler",
            onToggle ? "cursor-pointer" : null
          )}
        >
          <Col xs={colspan} md={colspan} className="pl-0 font-weight-bold">
            {this.getLabel(_title, {
              className: getComponentClassName(this.className, "title"),
              beforeTitle: this.props.beforeTitle,
              afterTitle: this.props.afterTitle,
              labelable: this.props.labelable
            })}
          </Col>
          {buttons}
          {collapseToggler}
        </Row>
      ) : null;

    let childrenRow = null;

    if (!collapseToggler || !this.state.collapsed || !this.shouldLazyLoad()) {
      childrenRow = <Row>{children}</Row>;
    }

    const inner = (
      <Container
        className={getComponentClassName(
          CollapsibleContainerBS,
          null,
          this.className
        )}
        data-collapsed={this.state.collapsed}
      >
        {this.props.headless ? null : title}
        {childrenRow}
      </Container>
    );

    if (this.props.wrapper) {
      return (
        <this.props.wrapper.as {...this.props.wrapper.props}>
          {inner}
        </this.props.wrapper.as>
      );
    }

    return inner;
  }
}

CollapsibleContainer.propTypes = {
  ...BaseInputProps(),
  ...CollapsibleContainerProps(),
  unit: PropTypes.string,
  beforeTitle: JSXProps(),
  afterTitle: JSXProps(),
  botDisabled: PropTypes.bool,
  labelable: PropTypes.bool, // see https://html.spec.whatwg.org/multipage/forms.html#category-label
  headless: PropTypes.bool // when true hide parent group
};

CollapsibleContainer.defaultProps = {
  botDisabled: true,
  labelable: true
};

export default CollapsibleContainer;
