import Picture from "@components-core/Picture";
import RouteLink from "@components-core/RouteLink";
import { DiscountBS } from "@style-variables";
import { stripHtmlTags } from "@utils/strings";
import React from "react";
import { Nav } from "react-bootstrap";

const renderImageThumbnail = props => discount => {
  let imgProps = { title: props.productItem.title };

  if (typeof props.productItem.img === "string") {
    imgProps.src = props.productItem.img;
  }
  if (typeof props.productItem.img === "object") {
    imgProps = { ...imgProps, ...props.productItem.img };
  }

  const tagDiscount =
    props.supportsTagDiscount && discount.value && props.tagDiscount ? (
      <div
        className={[DiscountBS, props.supportsTagDiscount]
          .filter(Boolean)
          .join(" ")}
        data-discount-percent={discount.percent}
        data-discount-value={discount.value}
      >
        {discount.percent}%
      </div>
    ) : null;

  const imageThumbnail = (
    <Nav.Link as="div" className="d-flex h-100 align-content-center">
      {tagDiscount}
      <Picture
        {...imgProps}
        title={stripHtmlTags(imgProps.title)}
        onClick={
          props.productItem.buttons
            ? props.productItem.buttons.product.onClick
            : null
        }
        className="card-img card-img-top"
        imgSize={props.imgSize}
        placeholder={props.placeholder}
        ribbons={props.ribbons}
      />
    </Nav.Link>
  );

  if (props.productItem.buttons) {
    return (
      <RouteLink
        to={props.productItem.buttons.product.href}
        title={props.productItem.title}
        className="text-center thumbnail h-100"
      >
        {imageThumbnail}
      </RouteLink>
    );
  }

  return imageThumbnail;
};

export { renderImageThumbnail };
