import PureComponent from "@components-core/PureComponent";
import { connectHOCs } from "@components-utils";
import SiteLogo from "@components/SiteLogo/SiteLogo";
import {
  EVENT_SEARCH_TOGGLED,
  PAGE_HASH_USER_EDIT_PASSWORD,
  PAGE_HASH_USER_EDIT_PROFILE,
  PAGE_HASH_USER_ORDER_HISTORY,
  PAGE_KEY_LOGOUT,
  PAGE_KEY_USER_PROFILE
} from "@constants";
import MenuBarHeaderProps from "@prop-types/MenuBarHeaderProps";
import { MenuBarHeaderBS } from "@style-variables";
import { mediaBreakpoint } from "@utils/breakpoints";
import { createCustomEvent } from "@utils/dom";
import { getComponentClassName } from "@utils/strings";
import React from "react";
import { Navbar } from "react-bootstrap";
import MediaQuery from "react-responsive";
import MenuBarButton from "./Button";
import MenuBarCartButton from "./MenuBarCartButton";
import MenuBarFavoriteButton from "./MenuBarFavoriteButton";
import MenuBarSearchButton from "./MenuBarSearchButton";
import MenuBarToggle from "./Toggle";

/**
 * @description Menu header component
 * @export
 * @class MenuBarHeader
 * @extends {PureComponent}
 */
class MenuBarHeader extends PureComponent {
  /**
   * @description Render the menu toggle element
   * @returns {JSX}
   * @memberof MenuBarHeader
   */
  renderMenuToggler() {
    if (!this.props.navbarToggle) {
      return null;
    }

    const navbarToggle = (
      <MenuBarToggle {...this.props.navbarToggle} inline={true} />
    );

    if (this.props.navbarToggle.position === "left") {
      return navbarToggle;
    }

    return navbarToggle;
  }

  /**
   * @description Render the brand element
   * @param {Boolean} [mobile=false] Whether the component is rendered for mobile devices
   * @returns {JSX}
   * @memberof MenuBarHeader
   */
  renderBrand(mobile = false) {
    return (
      <SiteLogo
        isMobile={mobile}
        className={MenuBarHeaderBS}
        wrapper={Navbar.Brand}
      />
    );
  }

  /**
   * @description Enhances the given menu bar user button props
   * @param {Object} item A menubar header user button props
   * @returns {Object} Returns the enhanced menubar header user button props, NULL to skip rendering
   * @memberof MenuBarHeader
   */
  getUserButtonProps(item) {
    const loginStatus = this.props.loginStatus;
    const props = {
      ...item,
      icon: { ...item.icon },
      className: "user-button",
      showTitle: true
    };

    props.icon.className = "text-muted";

    if (loginStatus.isLogged) {
      props.icon.className = "";
      props.tooltip = loginStatus.isAnonymous
        ? null
        : loginStatus.loggedUser.email.concat(
            loginStatus.loggedUser.customerNumber
              ? ` (${loginStatus.loggedUser.customerNumber})`
              : ""
          );

      const builtinLoginProvider = loginStatus.isAnonymous
        ? false
        : loginStatus.loggedUser &&
          loginStatus.loggedUser.provider === "builtin";

      // override the "/login" while logged
      props.href = this.props.pathfinder.get(PAGE_KEY_USER_PROFILE);

      if (loginStatus.isDeveloper) {
        props.icon.className = "text-danger";
        props.tooltip +=
          "\n" + this.props.i18n.TITLE_LOGGED_AS.replace("%role%", "DEV");
      } else if (loginStatus.isSuperAdmin) {
        props.icon.className = "text-success";
        props.tooltip +=
          "\n" +
          this.props.i18n.TITLE_LOGGED_AS.replace("%role%", "SuperAdmin");
      } else if (loginStatus.isAdmin) {
        props.icon.className = "text-primary";
        props.tooltip +=
          "\n" + this.props.i18n.TITLE_LOGGED_AS.replace("%role%", "Admin");
      }

      props.title =
        loginStatus.loggedUser.userName.length >
        loginStatus.loggedUser.firstName.length
          ? loginStatus.loggedUser.firstName
          : loginStatus.loggedUser.userName;

      const i18n = this.props.i18n.components.UserProfile;

      props.dropdown = [
        {
          url:
            this.props.pathfinder.get(PAGE_KEY_USER_PROFILE) +
            PAGE_HASH_USER_EDIT_PROFILE,
          title: i18n.BTN_EDIT_PROFILE
        },
        {
          url:
            this.props.pathfinder.get(PAGE_KEY_USER_PROFILE) +
            PAGE_HASH_USER_EDIT_PASSWORD,
          title: i18n.BTN_CHANGE_PASSWORD
        },
        { isSeparator: true },
        {
          url:
            this.props.pathfinder.get(PAGE_KEY_USER_PROFILE) +
            PAGE_HASH_USER_ORDER_HISTORY,
          title: i18n.BTN_ORDER_HISTORY,
          disabled: builtinLoginProvider
        },
        { isSeparator: true },
        {
          url: this.props.pathfinder.generate(PAGE_KEY_LOGOUT),
          title: i18n.BTN_LOGOUT
        }
      ];
    }

    return props;
  }

  /**
   * @description Enhances the given menu bar admin button props
   * @param {Object} item A menubar header admin button props
   * @returns {Object} Returns the enhanced menubar header admin button props, NULL to skip rendering
   * @memberof MenuBarHeader
   */
  getAdminButtonProps(item) {
    const loginStatus = this.props.loginStatus;
    const props = {
      ...item,
      icon: { ...item.icon },
      className: "admin-button"
      //showTitle: false
    };

    if (loginStatus.isLogged) {
    } else {
      return null;
    }

    return props;
  }

  /**
   * @description Render the menu header buttons
   * @param {Boolean} [mobile=false] When true render buttons for mobile devices
   * @returns {JSX}
   * @memberof MenuBarHeader
   */
  renderButtons(mobile = false) {
    const loginStatus = this.props.loginStatus;
    const trusted = (loginStatus.loggedUser || {}).trusted;

    const shouldRender = item => {
      let result = item.isSearchButton
        ? Boolean(this.props.searchEngine)
        : item.isFavoriteButton
        ? loginStatus.isAdmin
        : !item.isUserButton || trusted || this.props.security.enabled;

      return (
        result &&
        (!item.isAdminButton ||
          ((loginStatus.isAdmin ||
            loginStatus.isSuperAdmin ||
            loginStatus.isDeveloper) &&
            (trusted || this.props.security.adminToolbox)))
      );
    };

    const buttons = this.props.menubarHeader
      .filter(shouldRender)
      .map((item, index) => {
        const Factory = item.isCartButton
          ? MenuBarCartButton
          : item.isFavoriteButton
          ? MenuBarFavoriteButton
          : item.isSearchButton
          ? MenuBarSearchButton
          : MenuBarButton;

        let props = {
          ...item,
          icon: { ...item.icon },
          showTitle: true,
          i18n: this.props.i18n
        };

        if (item.isUserButton) {
          props = this.getUserButtonProps(item);
        } else if (item.isAdminButton) {
          props = this.getAdminButtonProps(item);
        }

        if (null === props) {
          return null;
        }

        if (item.isSearchButton) {
          props.href = undefined;
          props.onClick = e =>
            window.dispatchEvent(createCustomEvent(EVENT_SEARCH_TOGGLED));
          props.searchboxDocked = this.props.searchboxDocked;
        }

        return <Factory {...props} key={index} inline={mobile} />;
      });

    let rightToggler = null;

    if (this.props.navbarToggle) {
      if (this.props.navbarToggle.position === "left") {
        if (!buttons.length) {
          return null;
        }
      } else {
        rightToggler = this.renderMenuToggler();
      }
    }

    return (
      <div
        className={getComponentClassName(MenuBarHeaderBS, "buttons", "d-flex")}
      >
        {buttons}
        <MediaQuery {...mediaBreakpoint.lgDown}>{rightToggler}</MediaQuery>
      </div>
    );
  }

  renderMobileDevice() {
    let leftToggler = null;

    const navbarToggle = this.renderMenuToggler();

    if (navbarToggle) {
      if (this.props.navbarToggle.position === "left") {
        leftToggler = navbarToggle;
      }
    }

    return (
      <MediaQuery {...mediaBreakpoint.mobile}>
        <div className={getComponentClassName(MenuBarHeaderBS, "brand-top")}>
          {this.renderBrand(true)}
        </div>
        <Navbar collapseOnSelect variant="dark">
          {leftToggler}
          <div className="buttons">{this.renderButtons(true)}</div>
        </Navbar>
      </MediaQuery>
    );
  }

  renderDesktopDevice() {
    return (
      <MediaQuery {...mediaBreakpoint.default}>
        <Navbar collapseOnSelect variant="light">
          <div className="buttons">
            {this.renderBrand()}
            {this.renderButtons()}
          </div>
        </Navbar>
      </MediaQuery>
    );
  }

  render() {
    const className = getComponentClassName(
      MenuBarHeaderBS,
      null,
      this.props.className
    );

    return (
      <div className={className}>
        {this.props.children}
        {this.renderMobileDevice()}
        {this.renderDesktopDevice()}
      </div>
    );
  }
}

MenuBarHeader.propTypes = MenuBarHeaderProps();

MenuBarHeader.mapValueToProps = (value, ownProps) => ({
  menubarHeader: value.menubarHeader,
  i18n: value.i18n
});

MenuBarHeader.mapStateToProps = (state, ownProps) => null;
MenuBarHeader.mapDispatchToProps = null;

export default connectHOCs(MenuBarHeader, {
  withSite: true,
  withConnect: true
});
