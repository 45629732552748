import { ProductTransformer } from "@transformers/Product";
import { errorAddUnhandledException } from "../actions/error";
import {
  COMPARE_APPLY,
  COMPARE_APPLY_FAILURE,
  COMPARE_APPLY_ITEM_REMOVE,
  COMPARE_APPLY_REQUEST,
  COMPARE_APPLY_SUCCESS,
  COMPARE_ITEM_ADD,
  COMPARE_ITEM_REMOVE,
  COMPARE_RESET,
  COMPARE_SET
} from "../actionTypes";

/**
 * @description Toggle the compare function
 * @param {bool} enabled When true the comparison is activated, otherwise deactivated
 * @returns {Object} The action
 */
function compareSet(enabled) {
  return {
    type: COMPARE_SET,
    enabled
  };
}

/**
 * @description Adds an item to the compare items list
 * @param {number} id The item's Id
 * @returns {Object} The action
 */
function compareItemAdd(id) {
  return {
    type: COMPARE_ITEM_ADD,
    id
  };
}

/**
 * @description Removes an item from the compare items list
 * @param {number} id The item's Id
 * @returns {Object} The action
 */
function compareItemRemove(id) {
  return {
    type: COMPARE_ITEM_REMOVE,
    id
  };
}

/**
 * @description ALL items are compared and become active
 * @returns {Object} The action
 */
function compareApply() {
  return {
    type: COMPARE_APPLY
  };
}

/**
 * @description ALL active comparing/compared items are removed from the list of comparing items
 * @returns {Object} The action
 */
function compareReset() {
  return {
    type: COMPARE_RESET
  };
}

/**
 * @description Requesting fetching the compared items
 * @param {String} categoryId The product category search key
 * @param {Object} items The items to compare
 * @param {String} selectorType One of ProductSelectorType constants
 * @returns {Object} The action
 */
function applyCompareRequest(categoryId, items, selectorType) {
  return {
    type: COMPARE_APPLY_REQUEST,
    categoryId,
    items,
    selectorType
  };
}

/**
 * @description Updating the store with the successfully fetched compared items
 * @param {Object} data The fetched data
 * @returns {Object} The action
 */
function applyCompareSuccess(data) {
  return {
    type: COMPARE_APPLY_SUCCESS,
    ...data
  };
}

/**
 * @description Notifying the store about failing fetching the compared items
 * @param {Error} error
 * @returns {function}
 */
function applyCompareFailure(error, context, unhandled = true) {
  return dispatch => {
    if (unhandled) {
      dispatch(errorAddUnhandledException(error, context));
    }

    dispatch({
      type: COMPARE_APPLY_FAILURE,
      error: error.message
    });
  };
}

/**
 * @description Remove an item from the active comparison list
 * @param {number} id The item Id
 * @returns {Object} The action
 */
function applyCompareItemRemove(id) {
  return {
    type: COMPARE_APPLY_ITEM_REMOVE,
    id
  };
}

/**
 * @description Executes the request by dispatching the sync-action then fetching the items by active filter in an async-fashion
 * @param {String} categoryId The product category search key
 * @param {Array} productIds The items to compare
 * @param {String} selectorType One of ProductSelectorType constants
 * @param {Object} siteConfig
 * @returns {function}
 */
const fetchComparedData = (
  categoryId,
  productIds,
  selectorType,
  { siteId, userId, i18n, pathfinder, graphqlClient }
) => {
  return (dispatch, getState) => {
    // dispatch the sync-action then do the async-stuff
    dispatch(applyCompareRequest(categoryId, productIds, selectorType));

    /**
     * @description Adds a set of buttons to each product item
     * @param {Object} data The raw data as received from GraphQl API server
     * @returns {Array} Returns the transformed products
     */
    const productTransformer = data => ({
      productCompare: {
        ...data.productCompare,
        products: ProductTransformer(data.productCompare.products, {
          siteId,
          userId,
          i18n,
          pathfinder,
          graphqlClient,
          selectorType,
          impressionList: selectorType + "/compare",
          getState,
          dispatch
        })
      }
    });

    return graphqlClient.gqlModule(
      [
        import(
          /* webpackChunkName: "site" */ "@graphql-query/productCompare.gql"
        ),
        import(
          /* webpackChunkName: "site" */ "@graphql-query/productImageFragment.gql"
        ),
        import(
          /* webpackChunkName: "site" */ "@graphql-query/productImageFieldsFragment.gql"
        ),
        import(
          /* webpackChunkName: "site" */ "@graphql-query/relatedProductFragment.gql"
        ),
        import(
          /* webpackChunkName: "site" */ "@graphql-query/seoScoreFragment.gql"
        )
      ],
      {
        siteId,
        productIds,
        categoryId,
        selectorType: graphqlClient.asEnum(selectorType)
      },
      productTransformer
    );
  };
};

export {
  compareSet,
  compareItemAdd,
  compareItemRemove,
  compareApply,
  compareReset,
  applyCompareRequest,
  applyCompareSuccess,
  applyCompareFailure,
  applyCompareItemRemove,
  fetchComparedData
};
