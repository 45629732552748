const domParser = new DOMParser();

/**
 * @description Polyfill for browsers which doesn't support CustomEvent function
 * @param {string} type Is a DOMString containing the name of the event.
 * @param {Object} [params=null] The options passed to the CustomEvent
 * @param {boolean} [bubbles=true] Whether the event should bubble up through the event chain or not.
 * @param {boolean} [cancelable=true] Whether the event can be canceled.
 * @param {object} [params=null]
 * @returns {CustomEvent}
 * @see https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
 */
function createCustomEvent(
  type,
  params = null,
  bubbles = true,
  cancelable = true
) {
  let event = null;

  if (typeof CustomEvent === "function") {
    event = new CustomEvent(type, params);
  } else {
    event = document.createEvent("CustomEvent");
    event.initCustomEvent(type, bubbles, cancelable, params);
  }

  return event;
}

/**
 * @description Render a DOM element
 *
 * @param {String} tag The HTML tag name
 * @param {String} id The element id
 * @param {Object} style The element style
 * @param {Object} attribute The element attributes
 * @param {String} [html=null] The element inner HTML
 * @param {Object} parent The element parent node
 * @returns {Element} Returns the rendered DOM element
 */
function renderDOMElement(tag, id, style, attribute, html, parent) {
  const elem = document.createElement(tag);

  if (id) {
    elem.id = id;
  }

  if (style) {
    Object.keys(style).forEach(key => (elem.style[key] = style[key]));
  }

  if (attribute) {
    Object.keys(attribute).forEach(key => {
      if (null !== attribute[key] && "undefined" !== typeof attribute[key]) {
        elem.setAttribute("className" === key ? "class" : key, attribute[key]);
      }
    });
  }

  if (html) {
    elem.innerHTML = html;
  }

  var parentNode = parent || document.body;

  //parentNode.style.minHeight = "100vh";

  parentNode.appendChild(elem);

  return elem;
}

/**
 * @description Schedules a resource to be prefeched by the page
 * @param {String} id The link element ID
 * @param {String} rel See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/link#attr-rel
 * @param {String} href See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/link#attr-href
 * @param {String} as See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/link#attr-as
 * @param {String|Object} media See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/link#attr-media
 * @param {Boolean} [reuse=false] When true try to reuse/update the existing link if any, otherwise just append it to head
 * @returns {Element}
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/link
 */
function renderLink(id, rel, href, as, media, reuse = false) {
  if (reuse) {
    const el = document.head.querySelector(
      [
        "link",
        id ? `#${id}` : null,
        rel ? `[rel="${rel}"]` : null,
        as ? `[as="${as}"]` : null,
        media ? `[media="${media}"]` : null
      ]
        .filter(Boolean)
        .join("")
    );

    // reuse the existing element
    if (el) {
      el.href = href;

      return el;
    }
  }

  return renderDOMElement(
    "link",
    id,
    null,
    { as, href, media, rel },
    null,
    document.head
  );
}

/**
 * @description Schedules a resource to be prefeched by the page
 * @param {String} id The link element ID
 * @param {String} href The resource to prefetch
 * @param {String} as The type of content to prefetch (eg. image|script|style|video|font)
 * @param {String|Object} media The media query hint to prefetching the resource
 * @param {Boolean} [reuse=false] When true try to reuse/update the existing link if any, otherwise just append it to head
 * @returns {Element}
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Link_types/preload#other_resource_preloading_mechanisms
 */
function prefetch(id, href, as, media, reuse = false) {
  return renderLink(id, "prefetch", href, as, media, reuse);
}

/**
 * @description Schedules a resource to be preloaded by the page
 * @param {String} id The link element ID
 * @param {String} href The resource to preload
 * @param {String} as The type of content to preload (eg. image|script|style|video|font)
 * @param {String|Object} media The media query hint to prefetching the resource
 * @param {Boolean} [reuse=false] When true try to reuse/update the existing link if any, otherwise just append it to head
 * @returns {Element}
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Link_types/preload
 */
function preload(id, href, as, media, reuse = false) {
  return renderLink(id, "preload", href, as, media, reuse);
}

/**
 * @description Some browsers does not support options, expect two (x,y) arguments instead
 * @param {Object} options The ScrollToOptions
 */
function scrollWithFallback(options) {
  window.requestAnimationFrame(() => {
    options = options || { top: 0, left: 0 };
    try {
      window.scrollTo(options);
    } catch (error) {
      window.scrollTo(options.left, options.top);
    }
  });
}

/**
 * @description Get the number of pixels that the window/document content is scrolled vertically
 * @returns {number}
 */
function getScrollPage() {
  let docScrollTop = document.documentElement
    ? document.documentElement.scrollTop
    : 0;

  return window.scrollY || docScrollTop;
}

/**
 * @description Checks whether the Enter key was pressed
 * @param {KeyboardEvent} e The keyboard event
 * @param {boolean} [acceptSpacebar=true] When true then Spacebar is regarded also as pressing Enter
 * @returns {Boolean}
 */
function isEnterKeyPressed(e, acceptSpacebar = true) {
  return (
    ["Enter", acceptSpacebar ? " " : ""].includes(e.key) ||
    13 === e.which ||
    13 === e.keyCode ||
    13 === e.virtualKeyCode // synthetic event, see src/components/Admin/editors/MenuTree.js
  );
}

/**
 * @description Check whether the browser's DoNotTrack flag is enabled
 * @returns {Boolean} Return true when it's enabled, false otherwise
 */
function dntActive() {
  const dnt =
    navigator.msDoNotTrack || // Internet Explorer 9 and 10 vendor prefix
    window.doNotTrack || // IE 11 uses window.doNotTrack
    navigator.doNotTrack; // W3C

  return 1 === +dnt;
}

/**
 * @description Print the given element using browser built-in function
 * @param {HTMLElement} element
 * @param {String} title The name of the print window/filename
 */
function printElement(element, title) {
  if (!element) {
    throw new Error(`Invalid print target element`);
  }

  const previewElement = element.cloneNode(true);

  const beforePrint = e => {
    Array.from(document.querySelectorAll(`body > div`)).forEach(el => {
      el.classList.add(`d-print-none`);
    });

    document.body.appendChild(previewElement);
  };

  const afterPrint = e => {
    document.body.removeChild(previewElement);

    Array.from(document.querySelectorAll(`body > div`)).forEach(el =>
      el.classList.remove("d-print-none")
    );
  };

  window.addEventListener("beforeprint", beforePrint);
  window.addEventListener("afterprint", afterPrint);

  const prevTitle = document.title;
  if (title) {
    document.title = title;
  }

  window.print();
  document.title = prevTitle;
}

/**
 * @description Submits a form with the given params
 * @param {String} action The URL that processes the form submission.
 * @param {Object} params An object with the fields to submit.)
 * @param {string} [method="POST"] The HTTP method to submit the form with (POST|GET)
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/form#attributes_for_form_submission
 */
// function submitForm(action, params, enctype, method = "POST") {
//   const form = document.createElement("form");
//   form.method = method;
//   form.action = action;

//   const addFormField = name => {
//     const input = document.createElement("input");
//     input.type = "hidden";
//     input.name = name;
//     input.value = params[name];

//     form.appendChild(input);
//   };

//   Object.keys(params).forEach(addFormField);

//   document.body.appendChild(form);

//   form.submit();
// }

/**
 * @description Prettify a HTML string
 * @param {String} htmlString The input/unformatted HTML string
 * @param {number} [tab=0] The number of tabs the text should be indented initially
 * @returns {String} The formatted HTML string
 */
function prettifyHtml(htmlString, tab = 0) {
  let html = domParser.parseFromString(htmlString, "text/html");
  let formatHtml = "";

  const tabs = "\t".repeat(tab);

  html.body.childNodes.forEach((el, i) => {
    if ("#text" === el.nodeName) {
      const text = el.textContent.trim();
      if (text.length) {
        formatHtml += tabs + text + "\n";
      }
    } else {
      const innerHTML = el.innerHTML.trim();
      el.innerHTML = innerHTML.replace("\n", "").replace(/ +(?= )/g, "");

      if (el.children.length) {
        el.innerHTML = "\n" + prettifyHtml(innerHTML, tab + 1) + tabs;
      }

      formatHtml += tabs + el.outerHTML.trim() + "\n";
    }
  });

  return formatHtml;
}

/**
 * @description Enhance the element with dragging/positioning functionality
 * @param {Element} el The enhanced element
 * @param {Object} [options={}] The options
 */
function dragElement(el, options = {}) {
  const initialBackground = el.style.background;
  let pos1 = 0,
    pos2 = 0,
    pos3 = 0,
    pos4 = 0;

  const background = options.background || "#ffc107";
  const cursorGrab = (options.cursor || { grab: "grab" }).grab;
  const cursorGrabbing = (options.cursor || { grabbing: "grabbing" }).grabbing;

  el.style.cursor = cursorGrab;

  function getEvent(e) {
    return e instanceof TouchEvent ? e.touches[0] : e;
  }

  // Function to call when the element is being dragged
  function dragMouseDown(e) {
    e = e || window.event;

    // don't prevent target's default click/touch events
    //e.preventDefault();

    const ev = getEvent(e);

    pos3 = ev.clientX;
    pos4 = ev.clientY;

    document.onmouseup = closeDragElement;
    document.ontouchend = closeDragElement;

    document.onmousemove = elementDrag;

    // prevent `passive event listener` warning on browser console
    document.addEventListener("touchmove", elementDrag, { passive: false });
    //document.ontouchmove = elementDrag;

    el.style.cursor = cursorGrabbing;
    if (background) {
      el.setAttribute(
        "style",
        `background:${background} !important;` +
          (el.getAttribute("style") || "")
      );
    }
  }

  // Function to handle the dragging process
  function elementDrag(e) {
    e = e || window.event;

    // prevent scrolling while dragging
    e.preventDefault();

    const ev = getEvent(e);

    // Calculate the new position based on the mouse movement
    pos1 = pos3 - ev.clientX;
    pos2 = pos4 - ev.clientY;
    pos3 = ev.clientX;
    pos4 = ev.clientY;

    // Calculate the new top and left positions with constraints
    const containerWidth = el.offsetWidth;
    const containerHeight = el.offsetHeight;
    const containerLeft = el.offsetLeft - pos1;
    const containerTop = el.offsetTop - pos2;

    const maxX = window.innerWidth - containerWidth;
    const maxY = window.innerHeight - containerHeight;

    // Apply constraints to the new position
    const constrainedLeft = Math.max(0, Math.min(maxX, containerLeft));
    const constrainedTop = Math.max(0, Math.min(maxY, containerTop));

    el.style.left = constrainedLeft + "px";
    el.style.top = constrainedTop + "px";
  }

  // Function to stop dragging when mouse button is released
  function closeDragElement() {
    document.onmouseup = null;
    document.ontouchend = null;
    document.onmousemove = null;
    document.ontouchmove = null;
    el.style.cursor = cursorGrab;
    el.style.background = initialBackground;
  }

  // Attach the dragMouseDown function to the element
  el.onmousedown = dragMouseDown;

  // For touch devices
  el.ontouchstart = dragMouseDown;
}

export {
  createCustomEvent,
  getScrollPage,
  isEnterKeyPressed,
  renderDOMElement,
  scrollWithFallback,
  prefetch,
  preload,
  dntActive,
  printElement,
  prettifyHtml,
  dragElement
  //submitForm
};
