import GenericCarousel from "@components-core/GenericCarousel";
import Picture from "@components-core/Picture";
import RouteLink from "@components-core/RouteLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImageProps from "@prop-types/ImageProps";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import { LogoListBS } from "@style-variables";
import { mediaBreakpoint, isMobileDevice } from "@utils/breakpoints";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-bootstrap";
import MediaQuery from "react-responsive";

const LogoSlider = props => {
  const NavIcon = props => (
    <div>
      <FontAwesomeIcon {...props} size="2x" />
    </div>
  );

  const mdColSpan = Math.floor(12 / props.itemsPerSlide);

  const sliderProps = {
    indicators: false,
    controls: true,
    items: props.children,
    className: props.className,
    itemsPerSlide: props.itemsPerSlide,
    colSpan: { sm: 12, md: mdColSpan },
    prevIcon: <NavIcon icon="chevron-left" />,
    nextIcon: <NavIcon icon="chevron-right" />
  };

  return <GenericCarousel {...sliderProps} />;
};

const BrandsLogoList = props => {
  let _items = props.items;

  if (!_items.length) {
    if (props.placeholder) {
      _items = Array.from({ length: 3 }, () => ({
        img: {
          aspect: 0.17,
          sizes: { any: 176 }
        }
      }));
    }
  }

  const colspan = Math.floor(12 / _items.length);

  const items = _items.map((item, index) => {
    let imgProps = { title: item.title, alt: item.title };

    if (typeof item.img === "string") {
      imgProps.src = item.img;
    }
    if (typeof item.img === "object") {
      imgProps = { ...imgProps, ...item.img };
    }

    const itemClassName = getComponentClassName(LogoListBS, "item");

    const isMobile = isMobileDevice();

    const asLogoLink = (
      <RouteLink to={item.url} title={item.title}>
        <Picture
          key={index}
          {...imgProps}
          placeholder={props.placeholder}
          className={isMobile ? null : itemClassName}
        />
      </RouteLink>
    );

    const asCol = (
      <Col
        xs={12}
        sm={Math.max(3, colspan)}
        md={Math.max(2, colspan)}
        className={isMobile ? itemClassName : null}
      >
        {asLogoLink}
      </Col>
    );

    return (
      <MediaQuery {...mediaBreakpoint.mobile} key={index}>
        {matches =>
          matches
            ? asCol
            : _items.length > props.itemsPerSlide
            ? asLogoLink
            : asCol
        }
      </MediaQuery>
    );
  });

  const asSlider = (
    <LogoSlider
      className={getComponentClassName(LogoListBS, null, props.className)}
      itemsPerSlide={props.itemsPerSlide}
    >
      {items}
    </LogoSlider>
  );

  const asRow = (
    <Row className={getComponentClassName(LogoListBS, null, props.className)}>
      {items}
    </Row>
  );

  return (
    <MediaQuery {...mediaBreakpoint.mobile}>
      {matches =>
        matches ? asRow : items.length > props.itemsPerSlide ? asSlider : asRow
      }
    </MediaQuery>
  );
};

export default BrandsLogoList;

BrandsLogoList.propTypes = {
  ...ItemsAwareProps(false, null, PropTypes.shape(ImageProps())),
  placeholder: PropTypes.bool,
  itemsPerSlide: PropTypes.number
};

BrandsLogoList.defaultProps = {
  itemsPerSlide: 6
};
