// custom events
export const EVENT_SCROLL_TO_TOP = "scrollToTop";
export const EVENT_SCROLL_TO_BOTTOM = "scrollToBottom";
export const EVENT_APP_FORCE_UPDATE = "AppForceUpdate";
export const EVENT_LAZY_FORCE_UPDATE = "LazyAwareForceUpdate";
export const EVENT_COOKIE_USAGE_CHANGED = "CookieUsageChanged";
export const EVENT_MENU_TOGGLED = "menuToggled";
export const EVENT_SEARCH_TOGGLED = "searchToggled";
export const EVENT_PAGE_EDITOR_RESET = "pageEditorReset";
export const EVENT_PAGE_ADMIN_MAXIMIZE = "pageAdminToolboxMaximize";
export const EVENT_IMAGE_PREVIEW = "imagePreview";

// see the GraphQL schema for ProductSelectorType
export const PRODUCT_SELECTOR_TYPE_PRODUCT = "product";
export const PRODUCT_SELECTOR_TYPE_RELATED = "related";
export const PRODUCT_SELECTOR_TYPE_BRAND = "brand";
export const PRODUCT_SELECTOR_TYPE_CATEGORY = "category";
export const PRODUCT_SELECTOR_TYPE_SERIES = "series";
export const PRODUCT_SELECTOR_TYPE_SEARCH_RESULT = "searchResult";
export const PRODUCT_SELECTOR_TYPE_FAVORITE = "favorite";

// template binding page keys
export const PAGE_KEY_ADMIN_TOOLBOX = "admin-toolbox";
export const PAGE_KEY_ASSEMBLY_GUIDE = "assembly-guide";
export const PAGE_KEY_ASSEMBLY_INSTRUCTION = "assembly-instruction";
export const PAGE_KEY_B2B_ORDERING = "b2b-ordering";
export const PAGE_KEY_BUY_INFO = "buy-info";
export const PAGE_KEY_BUYING_GUIDE = "buying-guide";
export const PAGE_KEY_CHECKOUT = "checkout";
export const PAGE_KEY_COOKIE_POLICY = "cookie-policy";
export const PAGE_KEY_CUSTOM_MADE = "custom-made";
export const PAGE_KEY_CUSTOMER_SERVICE = "customer-service";
export const PAGE_KEY_FAVORITE = "favorite-list";
export const PAGE_KEY_HOME = "/";
export const PAGE_KEY_HTTP_ERROR_404 = "http-error-404";
export const PAGE_KEY_HTTP_ERROR_404_PRODUCT = "product-http-error-404";
export const PAGE_KEY_HTTP_ERROR_404_PRODUCT_CATEGORY =
  "product-category-http-error-404";
export const PAGE_KEY_HTTP_ERROR_404_SCREEN = "screen-http-error-404";
export const PAGE_KEY_IMPRINT = "imprint";
export const PAGE_KEY_INTEGRITY_POLICY = "integrity-policy";
export const PAGE_KEY_LOGIN = "login";
export const PAGE_KEY_LOGOUT = "logout";
export const PAGE_KEY_MEDIA = "media";
export const PAGE_KEY_PLACE_ORDER = "place-order";
export const PAGE_KEY_PRINTED_POOL_CLOTH = "printed-pool-cloth";
export const PAGE_KEY_PRODUCT = "product";
export const PAGE_KEY_PRODUCT_BRAND = "product-brand";
export const PAGE_KEY_PRODUCT_CATEGORY = "product-category";
export const PAGE_KEY_PRODUCT_SERIES = "product-series";
export const PAGE_KEY_RESET_PASSWORD = "reset-password";
export const PAGE_KEY_RECOVERY_PASSWORD = "recovery-password";
export const PAGE_KEY_SEARCH_RESULT = "search-result";
export const PAGE_KEY_USER_PROFILE = "user-profile";
export const PAGE_KEY_USER_REGISTRATION = "user-registration";

// page route hash
export const PAGE_HASH_USER_EDIT_PROFILE = "#edit-profile";
export const PAGE_HASH_USER_EDIT_PASSWORD = "#edit-password";
export const PAGE_HASH_USER_ORDER_HISTORY = "#order-history";
export const PAGE_HASH_USER_LOGOUT = "#logout";
export const PAGE_HASH_USER_EDIT_ADDRESSES = "#edit-addresses";
export const PAGE_HASH_USER_EDIT_FAVORITES = "#edit-favorites";
export const PAGE_HASH_USER_PAYMENT_HISTORY = "#payment-history";

export const PRODUCT_PAGE_SELECTORS = [
  PRODUCT_SELECTOR_TYPE_PRODUCT,
  PRODUCT_SELECTOR_TYPE_BRAND,
  PRODUCT_SELECTOR_TYPE_CATEGORY,
  PRODUCT_SELECTOR_TYPE_SERIES,
  PRODUCT_SELECTOR_TYPE_SEARCH_RESULT,
  PRODUCT_SELECTOR_TYPE_FAVORITE,
  PRODUCT_SELECTOR_TYPE_RELATED
];

// usage hints for pages routes
export const ROUTE_ROLE_NAVIGATION = "navigation";
export const ROUTE_ROLE_ADMIN = "admin";
export const ROUTE_ROLE_SECURITY = "security";
export const ROUTE_ROLE_CHECKOUT = "checkout";
export const ROUTE_ROLE_ERROR = "error";
export const ROUTE_ROLE_SEARCH = "search";
export const ROUTE_ROLE_CUSTOM = "custom";

// used for identifying search engines elements
export const SE_TAG_SEARCHBOX_ONLY = "searchbox-only";
export const SE_TAG_SEARCHRESULTS_ONLY = "searchresults-only";

// a search engine relevance preset
export const SE_OPTION_RELEVANCE_HIGH = 0.1;
export const SE_OPTION_RELEVANCE_QUITE = 0.5;
export const SE_OPTION_RELEVANCE_ACCEPTABLE = 0.25;

// https://github.com/GoogleChrome/lighthouse/blob/master/core/config/constants.js
const THROUGHPUT_ADJUSTMENT_FACTOR = 0.9;
export const MOBILE_SLOW_4G = 1.6 * THROUGHPUT_ADJUSTMENT_FACTOR;
export const MOBILE_REGULAR_3G = 0.7 * THROUGHPUT_ADJUSTMENT_FACTOR;

// the paging/filter search query parameter names
export const SEARCH_QUERY_PAGENO = "pag";
export const SEARCH_QUERY_FILTER = "filt";

// ad-hoc privileged filters keys
// see lsbolagen-front-nodejs/src/graphql/resolvers/query/utils.js
export const PRIVILEGED_FILTERS_KEY = "privileged_filters";
export const WITH_FILES_FILTERS_KEY = "with_files";
export const WITH_FLAGS_FILTERS_KEY = "with_flags";
export const WITH_STOCK_FILTERS_KEY = "with_stock";

// the ribbon item selection constans
export const RIBBON_SELECTION_ARTICLE_ONLY = "ARTICLE";
export const RIBBON_SELECTION_GROUP_ONLY = "GROUP";
export const RIBBON_SELECTION_ARTICLE_PAGE_ONLY = "ARTICLE_PAGE";
export const RIBBON_SELECTION_GROUP_ARTICLES = "ALL";

/**
 * @description Get the route name for the given product selector type
 * @param {String} selectorType One of PRODUCT_SELECTOR_TYPE_* constants
 * @returns {String} Returns the coresponding route name
 */
const getRouteByProductSelectorType = selectorType => {
  switch (selectorType) {
    case PRODUCT_SELECTOR_TYPE_PRODUCT:
    case PRODUCT_SELECTOR_TYPE_RELATED:
      return PAGE_KEY_PRODUCT;
    case PRODUCT_SELECTOR_TYPE_SERIES:
      return PAGE_KEY_PRODUCT_SERIES;
    case PRODUCT_SELECTOR_TYPE_BRAND:
      return PAGE_KEY_PRODUCT_BRAND;
    case PRODUCT_SELECTOR_TYPE_SEARCH_RESULT:
      return PAGE_KEY_SEARCH_RESULT;
    case PRODUCT_SELECTOR_TYPE_CATEGORY:
      return PAGE_KEY_PRODUCT_CATEGORY;
    case PRODUCT_SELECTOR_TYPE_FAVORITE:
      return PAGE_KEY_FAVORITE;
    default:
      throw new Error(`Unexpected selectorType="${selectorType}"`);
  }
};

/**
 * @description Find the route path for the given selector type and category slug
 * @param {Object} pathfinder The route pathfinder
 * @param {String} selectorType The selector which gives the route name. One of PRODUCT_SELECTOR_TYPE_* constants.
 * @param {String} slug A string passed as route parameter (category slug)
 * @returns {String} Returns the route's path
 */
const findPathBySelectorType = (pathfinder, selectorType, slug) =>
  pathfinder.get({
    name: getRouteByProductSelectorType(selectorType),
    params: { categoryId: slug }
  });

/**
 * @description Find the path based on the item url/slug properties
 * @param {Object} pathfinder The route pathfinder
 * @param {Object} item An object with (at least) one of the following keys: URL|ROUTE_NAME|CATEGORY_SLUG|BRAND_SLUG|PRODUCT_SERIES_SLUG
 * @returns {String} Returns the item's route path on success, NULL otherwise
 */
const getRoutePathByItem = (pathfinder, item) => {
  const { CATEGORY_SLUG, BRAND_SLUG, PRODUCT_SERIES_SLUG, URL, ROUTE_NAME } =
    item;

  if (
    !(CATEGORY_SLUG || BRAND_SLUG || PRODUCT_SERIES_SLUG || URL || ROUTE_NAME)
  ) {
    return null;
  }

  const selectorType = CATEGORY_SLUG
    ? PRODUCT_SELECTOR_TYPE_CATEGORY
    : BRAND_SLUG
    ? PRODUCT_SELECTOR_TYPE_BRAND
    : PRODUCT_SELECTOR_TYPE_SERIES;

  const slug = CATEGORY_SLUG || BRAND_SLUG || PRODUCT_SERIES_SLUG;

  return (
    URL ||
    (/^https?:\/\//.test(BRAND_SLUG) ? BRAND_SLUG : null) ||
    (ROUTE_NAME
      ? pathfinder.get(ROUTE_NAME)
      : findPathBySelectorType(pathfinder, selectorType, slug))
  );
};

export {
  findPathBySelectorType,
  getRouteByProductSelectorType,
  getRoutePathByItem
};
