import PropTypes from "prop-types";
import CurrencyProps from "./CurrencyProps";

export default function ProductPriceProps() {
  return {
    newPrice: PropTypes.number,
    oldPrice: PropTypes.number,
    inline: PropTypes.bool,
    ...CurrencyProps,
    placeholder: PropTypes.bool
  };
}
