import React from "react";
import RouteLinkImage from "@components-core/RouteLinkImage";
import { carouselOptimalDelayFactor } from "@components-utils";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import { MarqueeWidgetBS } from "@style-variables";
import { mediaBreakpoint } from "@utils/breakpoints";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import { Carousel, Col } from "react-bootstrap";
import MediaQuery from "react-responsive";
import PureComponent from "./PureComponent";

/**
 * @description Renders a static/carousel banner of linkified brands-like icons/images
 * @param {Object} props - The component properties
 * @returns Returns the JSX element
 */
export default class MarqueeWidget extends PureComponent {
  constructor(props) {
    super(props);

    this.getOptimalColspan = this.getOptimalColspan.bind(this);
    this.columnFilter = this.columnFilter.bind(this);
    this.carouselFilter = this.carouselFilter.bind(this);
    this.getBreakpointWidth = this.getBreakpointWidth.bind(this);

    this.maxcols = 12; //max Bootstrap responsive columns count

    this.state = { interval: props.intervalDelay ? null : props.interval };

    this.intervalDelayTimer = null;
  }

  componentDidMount() {
    if (this.props.intervalDelay && null !== this.props.interval) {
      this.intervalDelayTimer = setTimeout(
        () =>
          this.setState({
            interval: this.props.interval
          }),
        carouselOptimalDelayFactor() * this.props.intervalDelay
      );
    }
  }

  componentWillUnmount() {
    if (this.intervalDelayTimer) {
      clearTimeout(this.intervalDelayTimer);
    }
  }

  /**
   * @description Get  the optimal max/min deviceWidth for MediaQuery wrapper
   * @returns The max breakpoint width
   * @memberof MarqueeWidget
   */
  getBreakpointWidth() {
    const breakpoints = Object.keys(mediaBreakpoint.size).map(
      key => mediaBreakpoint.size[key]
    );

    let maxWidth = breakpoints.find(
      width => this.props.items.length * 250 <= width
    );

    return maxWidth ? maxWidth : mediaBreakpoint.size.xl;
  }

  /**
   * @description Calculate the optimal colspan increment given the number of items
   * @returns {number} Returns the optimal column span given the number of items
   * @memberof MarqueeWidget
   */
  getOptimalColspan() {
    return Math.max(
      0,
      Math.round(
        (this.maxcols -
          this.props.items.reduce(
            (carry, item) => carry + (item.colspan ? +item.colspan : 1),
            0
          )) /
          this.props.items.length
      )
    );
  }

  /**
   * @description Span the items by columns (used by default for desktop-like devices)
   * @param {*} items
   * @returns {array} Returns the items wrapped within a responsive column
   * @memberof MarqueeWidget
   */
  columnFilter(items) {
    let remaining = this.maxcols;

    const optimalColspan = this.getOptimalColspan();

    return items.map((item, key) => {
      let md =
        optimalColspan +
        (this.props.items[key].colspan ? +this.props.items[key].colspan : 1);

      md = key === items.length - 1 ? remaining : Math.min(remaining, md);

      remaining -= md;

      return (
        <Col
          key={key}
          xs={"12"}
          md={md}
          className={getComponentClassName(MarqueeWidgetBS, "item")}
        >
          {item}
        </Col>
      );
    });
  }

  /**
   * @description Wraps the items within a carousel slides (used by default for mobile-like devices)
   * @param {*} items
   * @returns {array} Returns the items wrapped within a responsive carousel slide
   * @memberof MarqueeWidget
   */
  carouselFilter(items) {
    return items.map((item, key) => {
      return (
        <Carousel.Item
          key={key}
          className={getComponentClassName(MarqueeWidgetBS, "carousel-item")}
        >
          {item}
        </Carousel.Item>
      );
    });
  }

  render() {
    if (!this.props.enabled) {
      return null;
    }

    const maxWidth = this.getBreakpointWidth();

    let items = this.props.items.slice(0, this.props.items.length - 1);

    // SUM(colspan(n-1:th)) items
    const firstColspan = items.reduce(
      (carry, item) => carry + +item.colspan,
      0
    );

    // colspan of n:th item
    const remainingColspan = 12 - firstColspan;

    items = items.concat({
      ...this.props.items.slice(-1).pop(),
      colspan: remainingColspan
    });

    return (
      <div
        className={getComponentClassName(
          MarqueeWidgetBS,
          null,
          this.props.className
        )}
        style={this.props.style}
      >
        <MediaQuery minDeviceWidth={maxWidth}>
          {matches => {
            if (matches) {
              return (
                <RouteLinkImage items={items} filters={[this.columnFilter]} />
              );
            } else {
              return (
                <Carousel
                  controls={false}
                  indicators={false}
                  keyboard={false}
                  interval={this.state.interval}
                  className={getComponentClassName(MarqueeWidgetBS, "carousel")}
                >
                  {new RouteLinkImage({
                    items: items,
                    filters: [this.carouselFilter]
                  }).getItems()}
                </Carousel>
              );
            }
          }}
        </MediaQuery>
      </div>
    );
  }
}

MarqueeWidget.propTypes = {
  ...ItemsAwareProps,
  interval: PropTypes.number,
  intervalDelay: PropTypes.number,
  enabled: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object
};

MarqueeWidget.defaultProps = {
  enabled: true,
  intervalDelay: 3000,
  interval: 4000
};
