import { PAGE_KEY_CHECKOUT } from "@constants";
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "./utils";

const TEMPLATE_KEY = "common/CheckoutTemplate";

const CheckoutTemplate = props => {
  const defaultTemplate = [
    {
      component: "ShoppingCart/ShoppingCart"
    },
    {
      component: "Checkout/RelatedProducts"
    },
    {
      component: "Checkout/CustomerAddress"
    },
    {
      component: "Checkout/OtherOptions",
      props: {
        optionType: "assembly",
        optionPrefix: "3"
      }
    },
    {
      component: "Checkout/OtherOptions",
      props: {
        optionType: "ordering",
        optionPrefix: "3"
      }
    },
    {
      component: "Checkout/PaymentShipment",
      props: {
        optionPrefix: "3"
      }
    },
    {
      component: "Checkout/Subtotal"
    },
    {
      component: "Checkout/PlaceOrder"
    },
    ...props.widgets
  ];

  return mergeTemplateItems(defaultTemplate, props.templates).map(
    createLazyTemplateItem
  );
};

CheckoutTemplate.mapValueToTemplateProps = {
  pageKey: PAGE_KEY_CHECKOUT,
  templateKey: TEMPLATE_KEY
};

export default CheckoutTemplate;
