import BootstrapFormCheck from "@components-core/BootstrapFormCheck";
import ExternalWidget from "@components-core/ExternalWidget";
import PureComponent from "@components-core/PureComponent";
import SearchableDropdown from "@components/SearchableDropdown/SearchableDropdown";
import { EVENT_SCROLL_TO_TOP } from "@constants";
import GoogleReCaptchaProps from "@prop-types/GoogleReCaptchaProps";
import JSXProps from "@prop-types/JSXProps";
import TitleTextProps from "@prop-types/TitleTextProps";
import { FormValidationBS } from "@style-variables";
import { debug } from "@utils/debug";
import { createCustomEvent, isEnterKeyPressed } from "@utils/dom";
import { scrollIntoView } from "@utils/functions";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Form } from "react-bootstrap";
import PasswordControl from "./PasswordControl";

// TODO implement built-in recaptcha control based on password-input

/**
 * @description A React component that renders a validation-aware form
 * @export
 * @class FormValidation
 * @extends {PureComponent}
 */
export default class FormValidation extends PureComponent {
  static RECAPTCHA_RENDERING = "recaptcha-rendering";
  static RECAPTCHA_VALIDATED = "recaptcha-validated";

  constructor(props) {
    super(props);

    this.handleFormInputChange = this.handleFormInputChange.bind(this);
    this.setInputGroupValidateStatus =
      this.setInputGroupValidateStatus.bind(this);
    this.onControlKeyUp = this.onControlKeyUp.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);

    this.state = {
      feedback: this.getDefaultFeedbackState(),
      validated: false,
      alert: false,
      reCaptcha: {
        token: null,
        state: null,
        onValidated: null,
        container: React.createRef()
      }
    };

    // create a DOM Ref for all form fields, for validation feedback purpose
    this.feedbackRefs = Object.keys(this.state.feedback)
      .map(prefix => ({
        [prefix]: Object.keys(this.validationRulesToFeedback()).reduce(
          (carry, name) => Object.assign(carry, { [name]: React.createRef() }),
          {}
        )
      }))
      .reduce((carry, prefixRefs) => Object.assign(carry, prefixRefs), {});

    this.reCaptchaRendered = false;
  }

  componentDidUpdate() {
    if (FormValidation.RECAPTCHA_RENDERING === this.state.reCaptcha.state) {
      this.renderReCaptcha();
    }
  }

  /**
   * @description Helper for scrolling to the top of the screen
   * @memberof FormValidation
   */
  scrollToTop() {
    window.dispatchEvent(createCustomEvent(EVENT_SCROLL_TO_TOP));
  }

  /**
   * @description Checks whether the reCAPTCHA has been validated and a token has been received
   * @returns {Boolean}
   * @memberof FormValidation
   */
  hasReCaptchaToken() {
    return Boolean(this.state.reCaptcha.token);
  }

  /**
   * @description Checks whether the reCAPTCHA plugin should be mounted into DOM
   * @returns {Boolean}
   * @memberof FormValidation
   */
  shouldMountReCaptcha() {
    return !(
      this.props.reCaptcha.disabled ||
      FormValidation.RECAPTCHA_VALIDATED === this.state.reCaptcha.state ||
      this.hasReCaptchaToken()
    );
  }

  //componentDidMount() {
  // setTimeout(() => {
  //   const prefix = this.getStatic("prefix");

  //   this.getFormFields(prefix).forEach(item => {
  //     const name = item[0];

  //     const el = this.feedbackRefs[prefix][name].current;

  //     if (el) {
  //       el.dispatchEvent(new Event("blur"));
  //     }
  //   });
  // }, 5); // trigger the event after React mounting
  //}

  /**
   * @description Get a static value of the current instance
   * @param {String} key The static value property/function name
   * @param {boolean} [isFunction=false] When true the key is assumed to be a function, otherwise property
   * @returns {*}
   * @memberof FormValidation
   */
  getStatic(key, isFunction = false) {
    const resolver = this.constructor[key];

    if (isFunction) {
      return resolver();
    }

    return resolver;
  }

  /**
   * @description Get the validation rules. Should be defined in their order of validation (should be in-sync with the form layout)
   * @param {String} [fieldName = null] When specified return the validation rule for this field only.
   * @returns {Object} Returns an object of field name to:  minimum field length / a function which validates the field
   * @memberof FormValidation
   */
  getValidationRules(fieldName = null) {
    this.classExtendError();
  }

  /**
   * @description Get the default feedback state
   * @see FormValidation.validationRulesToFeedback
   * @returns {Object}
   * @memberof FormValidation
   */
  getDefaultFeedbackState() {
    this.classExtendError();
  }

  /**
   * @description Get the Redux action for handling the input change
   * @param {String} prefix
   * @param {String} name
   * @memberof FormValidation
   * @returns {callable}
   */
  getFormChangeAction(prefix, name) {
    this.classExtendError();
  }

  /**
   * @description Get the props to pass to the Redux action that handles the input change
   * @param {Event} e The input change event
   * @param {String} prefix
   * @param {String} name The input field name
   * @memberof FormValidation
   * @returns {Object}
   */
  getFormChangeActionProps(e, prefix, name) {
    this.classExtendError();
  }

  /**
   * @description Get the state key
   * @param {String} prefix
   * @memberof FormValidation
   * @returns {String}
   */
  getStateKey(prefix) {
    this.classExtendError();
  }

  /**
   * @description Get the definition of the form fields
   * @param {String} prefix The group prefix the fields belongs to
   * @memberof FormValidation
   * @returns {Array} Returns an Array[T], or Array[Array[T]], where T = {name:String, grpProps: Object, ctrlProps: Object }
   */
  getFormFields(prefix) {
    this.classExtendError();
  }

  /**
   * @description Get the form rendered fields
   * @memberof FormValidation
   * @returns {JSX}
   */
  getRenderedFields() {
    this.classExtendError();
  }

  /**
   * @description Event which triggers when Enter key is released on any form control/input
   * @param {Event} e The event
   * @memberof FormValidation
   */
  onControlKeyUp(e) {
    //should be implemented by child class
  }

  /**
   * @description Get the default feedback state from the validation rules
   * @returns {Object}
   * @memberof FormValidation
   */
  validationRulesToFeedback() {
    // an object with same keys and empty string value
    return Object.keys(this.getValidationRules())
      .map(key => ({ [key]: "" }))
      .reduce((carry, item) => Object.assign(carry, item), {});
  }

  /**
   * @description Sets the validation flag of the refered input element's form group
   * @param {Element} element The DOM element of an input (it could be also a bieffect of a fake-event)
   * @param {Boolean} [wasValidated=false] When true sets the flag, otherwise remove it
   * @param {Boolean} [setState=true] When true sets the new state too
   * @memberof FormValidation
   */
  setInputGroupValidateStatus(element, wasValidated = false, setState = true) {
    // check if the element is a bieffect of a fake-event
    if (element instanceof Element) {
      const classList = element.closest(".form-group").classList;
      const className = "was-validated";

      if (wasValidated) {
        classList.add(className);
      } else {
        classList.remove(className);
      }
    }

    // this goes even for a fake-event
    if (setState === true) {
      this.setState({ validated: wasValidated });
    }
  }

  /**
   * @description Validates the given object by using the validation rules
   * @param {Object} object The validated object
   * @param {string} prefix The key of feedback status
   * @returns {Object} Returns the validated object
   * @memberof OrderValidator
   */
  validateObject(object, prefix) {
    const rules = this.getValidationRules();

    // store the validation into a validation buffer
    let newState = {};
    const elements = [];

    // commit the validation buffer
    const commit = () => {
      elements.forEach(el => this.setInputGroupValidateStatus(el, true, false));

      this.setState({
        feedback: { ...this.state.feedback, [prefix]: newState }
      });
    };

    // build-up the validation buffer based on the validation rules
    Object.keys(rules).forEach(key => {
      let valid;
      let reason;

      const el = this.feedbackRefs[prefix][key].current;

      if (!el || el.disabled || el.readOnly) {
        return;
      }

      const i18n =
        this.props.i18n && this.props.i18n.FORM_VALIDATION
          ? this.props.i18n.FORM_VALIDATION
          : {
              error: "Invalid %prefix%: %key% %reason%",
              pattern: "has invalid pattern",
              array: "does not meet criteria (%valid_items%)",
              string: "length should be at least %length% chars"
            };

      const setup = this.props.setup || {};
      const fields = setup.fields || {};

      const value =
        "undefined" === typeof object[key] || null === object[key]
          ? ""
          : object[key];

      if (rules[key] instanceof RegExp) {
        valid = rules[key].test(object[key]);
        reason = i18n.pattern;
      } else if ("function" === typeof rules[key]) {
        valid = rules[key](value, object);
        reason = i18n.pattern;
        if (Array.isArray(valid)) {
          reason = i18n.array.replace("%valid_items%", valid.join(", "));

          valid = !valid.length;
        }
      } else {
        valid = value.toString().length >= rules[key];
        reason = i18n.string.replace("%length%", rules[key]);
      }

      elements.push(el);

      const feedback = valid
        ? ""
        : i18n.error
            .replace("%prefix%", setup.text || prefix)
            .replace("%key%", fields[key] || key)
            .replace("%reason%", reason);

      newState = { ...newState, [key]: feedback };

      if (!valid) {
        commit();

        scrollIntoView(el);
        el.focus();

        throw new Error(feedback);
      }
    });

    commit();

    return object;
  }

  /**
   * @description Handle the form input change
   * @param {Event} e The input change event
   * @param {String} prefix The form prefix
   * @param {String} name The property name associated to the input
   * @memberof FormValidation
   */
  handleFormInputChange(e, prefix, name) {
    if (name) {
      const action = this.getFormChangeAction(prefix, name);

      const props = this.getFormChangeActionProps(e, prefix, name);

      action(props);

      this.setInputGroupValidateStatus(e.target);
    }
  }

  /**
   * @description Build a form group input
   * @param {String} prefix The form prefix
   * @param {String} name The property name associated to the input
   * @param {Object} [grpProps=null] The form group properties
   * @param {Object} [ctrlProps=null] The form control properties
   * @returns {JSX}
   * @memberof FormValidation
   */
  fieldBuilder(prefix, name, grpProps = null, ctrlProps = null) {
    const _ctrlProps = { ...ctrlProps };

    const prefixMe = string => {
      return prefix + string[0].toUpperCase() + string.slice(1);
    };

    const refs = this.feedbackRefs[prefix];
    const state = this.props[this.getStateKey(prefix)] || {};
    const fields = this.props.setup.fields;
    const placeholders = this.props.setup.placeholders || {};
    const feedback = this.state.feedback[prefix];

    const rule = [];
    if (
      "undefined" !== typeof this.getValidationRules(name) &&
      "function" !== typeof this.getValidationRules(name)
    ) {
      rule.minLength = this.getValidationRules(name);
    }

    const hasFeedback = Boolean(feedback[name]);

    let grpLabel = null;
    let ctrlLabel = null;
    let fwdProps = {};
    let ctrlFeedback = (
      <Form.Control.Feedback type="invalid">
        {feedback[name]}
      </Form.Control.Feedback>
    );

    const onChange = e => {
      if (_ctrlProps && "function" === typeof _ctrlProps.onChange) {
        _ctrlProps.onChange(e);
      }

      return this.handleFormInputChange(e, prefix, name);
    };

    if (_ctrlProps && _ctrlProps.type === "checkbox") {
      ctrlLabel = fields[name];
      fwdProps = {
        inputRef: refs[name],
        as: BootstrapFormCheck,
        feedback: feedback[name],
        onChange
      };
      ctrlFeedback = null;
    } else {
      const required = _ctrlProps.required ? (
        <span className="text-danger px-1">*</span>
      ) : null;

      grpLabel = (
        <Form.Label>
          {fields[name]}
          {required}
        </Form.Label>
      );
      fwdProps = { ref: refs[name] };

      if (_ctrlProps && _ctrlProps.type === "password") {
        fwdProps.feedback = ctrlFeedback;
      }

      if (_ctrlProps && _ctrlProps.type === "select") {
        fwdProps.as = "select";

        // searchable dropdown
        if (_ctrlProps.searchable) {
          fwdProps.as = SearchableDropdown;
          fwdProps.value = state[name];
          fwdProps.onChange = onChange;
          fwdProps.onAutofill = _ctrlProps.onAutofill;
          fwdProps.feedback = feedback[name];
          ctrlFeedback = null;

          _ctrlProps.items = _ctrlProps.items.map(item => ({
            ...item,
            selected: state[name] === item.key
          }));

          delete _ctrlProps.searchable;
        }
        // HTML built-in dropdown
        else {
          fwdProps.children = _ctrlProps.items.map(item => (
            <option key={item.key} value={item.key}>
              {item.value}
            </option>
          ));

          delete _ctrlProps.items;
        }

        delete _ctrlProps.searchable;
        delete _ctrlProps.type;
      }
    }

    const ctrlPlaceholder = placeholders[name];

    const props = {
      ..._ctrlProps,
      ...fwdProps,
      onBlur: onChange,
      defaultValue: state[name],
      ...rule,
      isInvalid: hasFeedback,
      label: ctrlLabel,
      placeholder: ctrlPlaceholder,
      onKeyUp: e => {
        if (isEnterKeyPressed(e, false)) {
          if (e.currentTarget.dispatchEvent(new Event("blur"))) {
            setTimeout(() => this.onControlKeyUp(e), 20); // wait React state flush
          }
        }
      }
    };

    // TODO check-out how we pass the refs to the FormControlFactory
    const FormControlFactory =
      "password" === ctrlProps.type
        ? PasswordControl
        : "select" === ctrlProps.type && ctrlProps.searchable
        ? SearchableDropdown
        : Form.Control;

    return (
      <Form.Group {...(grpProps || [])} controlId={prefixMe(name)}>
        {grpLabel}
        <FormControlFactory
          {...{
            ...props,
            disabled: this.props.disabled || props.disabled,
            readOnly: this.props.readOnly || props.readOnly
          }}
        />
        {ctrlFeedback}
      </Form.Group>
    );
  }

  /**
   * @description Finds the field definition within the given fields
   * @param {Array} fields The form fields definitions
   * @param {String} name The field name
   * @returns {Array} Returns the field definition on success, null otherwise
   * @memberof FormValidation
   */
  findFormFieldByName(fields, name) {
    let result = null;

    fields.every(item => {
      if (Array.isArray(item[0])) {
        result = this.findFormFieldByName(item, name);
      } else {
        result = item[0] === name ? item : null;
      }

      return result === null;
    });

    return result;
  }

  /**
   * @description Finds the field definition for a given name
   * @param {String} prefix The form prefix
   * @param {String} name The field name
   * @returns {Array} Returns the field definition on success, null otherwise
   * @memberof FormValidation
   */
  findFormField(prefix, name) {
    return this.findFormFieldByName(this.getFormFields(prefix), name);
  }

  /**
   * @description Render the form fields
   * @param {String} prefix
   * @param {Boolean} [disabled=false] When true then disabled the fields by default
   * @param {Boolean} [hidden=false] When true then render the fields as hidden
   * @returns {JSX}
   * @memberof FormValidation
   */
  renderFields(prefix, disabled = false, hidden = false) {
    /**
     * @description Injects the `key` into the given props
     * @param {Object} builderProps The control props
     * @param {number} key The control key
     * @param {Boolean} autofocus When `true` the control `autofocus` attribute is set
     * @returns {Object}
     */
    const withBuiderPropsKey = (builderProps, key, autofocus) => {
      const props = [...builderProps];
      props[1] = { ...(props[1] || []), key };

      if (autofocus) {
        props[2].autoFocus = true;
      }

      props[1].disabled = props[1].disabled || disabled;
      props[2].disabled = props[2].disabled || disabled;

      if (hidden) {
        props[1].className = [props[1].className, "d-none"]
          .filter(Boolean)
          .join(" ");
      }

      return props;
    };

    return this.getFormFields(prefix).map((item, i) => {
      if (Array.isArray(item[0])) {
        return (
          <Form.Row key={i} className={hidden ? "d-none" : null}>
            {item.map((col, j) =>
              this.fieldBuilder(prefix, ...withBuiderPropsKey(col, j, !i))
            )}
          </Form.Row>
        );
      } else {
        return this.fieldBuilder(prefix, ...withBuiderPropsKey(item, i, !i));
      }
    });
  }

  /**
   * @description Render the form footer buttons
   * @returns {JSX}
   * @memberof FormValidation
   */
  renderButtons() {
    return this.props.buttons;
  }

  /**
   * @description Checks whether the form fields contains at least one required field
   * @returns {Boolean}
   * @memberof FormValidation
   */
  hasRequiredFields() {
    return this.getFormFields().reduce(
      (carry, item) =>
        carry ||
        (Array.isArray(item[0])
          ? item
              //.filter(Boolean)
              .reduce(
                (carry, item) => carry || (item && item[2] && item[2].required),
                false
              )
          : item && item[2] && item[2].required),
      false
    );
  }

  /**
   * @description Get the validation form
   * @returns {JSX}
   * @memberof FormValidation
   */
  getForm() {
    const fields =
      FormValidation.RECAPTCHA_RENDERING === this.state.reCaptcha.state ||
      FormValidation.RECAPTCHA_VALIDATED === this.state.reCaptcha.state
        ? null
        : this.getRenderedFields();

    const reCaptcha = this.shouldMountReCaptcha()
      ? this.mountReCaptcha()
      : null;

    const label =
      this.props.i18n && this.props.i18n.FORM_VALIDATION
        ? this.props.i18n.FORM_VALIDATION.required
        : "Required Fields";

    // a hint for required fields
    const requiredHint =
      fields && this.hasRequiredFields() ? (
        <Form.Group className="text-center text-sm-left">
          <Form.Label>
            <span className="text-danger px-1">*</span>
            {label}
          </Form.Label>
        </Form.Group>
      ) : null;

    const buttons = this.renderButtons();

    return (
      <Form
        className={getComponentClassName(this.props.className)}
        validated={this.state.validated}
      >
        {fields}
        {requiredHint}
        {reCaptcha}
        {buttons}
      </Form>
    );
  }

  /**
   * @description Mount the reCAPTCHA plugin
   * @returns {JSX} Returns the mounted plugin
   * @memberof FormValidation
   */
  mountReCaptcha() {
    const { disabled, language } = this.props.reCaptcha;

    if (disabled) {
      return null;
    }

    // https://developers.google.com/recaptcha/docs/display#javascript_resource_apijs_parameters
    const params = { render: "explicit" };
    if (language) {
      params.hl = language;
    }
    const paramStr =
      "?" +
      Object.keys(params)
        .reduce((carry, key) => carry.concat(`${key}=${params[key]}`), [])
        .join("&");

    // we should give the plugin 500ms to be able to mount
    return (
      <ExternalWidget
        disabled={false}
        headless={false}
        delay={500}
        lazy={false}
        type={ExternalWidget.WIDGET_TYPE_URI}
        id="google-recaptcha-plugin"
        assets={[
          {
            as: "script",
            source: `https://www.google.com/recaptcha/api.js${paramStr}`
          }
        ]}
        className="mx-0"
        onUnmount={() =>
          window.grecaptcha &&
          this.state.reCaptcha.state &&
          window.grecaptcha.reset(this.state.reCaptcha.container.current)
        }
      >
        <div
          ref={this.state.reCaptcha.container}
          className="g-recaptcha mx-auto"
        />
      </ExternalWidget>
    );
  }

  /**
   * @description Render the Google reCAPTCHA plugin
   * @param {number} [level=0] Internal use. The function calling nesting level
   * @param {Error} [error=null] Internal use. The last error.
   * @memberof FormValidation
   */
  renderReCaptcha(level = 0, error = null) {
    if (level > 3) {
      const unexpectedError = this.props.i18n
        ? [
            `<h4>${this.props.i18n.UNEXPECTED_ERROR_TITLE}</h4>`,
            `<p>${this.props.i18n.UNEXPECTED_ERROR_TEXT}</p>`,
            `<p>${this.props.i18n.UNEXPECTED_ERROR_RESOLUTION}</p>`
          ].join("")
        : "<p>Unexpected error, please reload the page</p>";

      const errorMsg = error
        ? error instanceof Error
          ? error
          : error
        : unexpectedError;

      const element = this.state.reCaptcha.container.current;

      const child = document.createElement("p");
      child.innerHTML = errorMsg;
      child.setAttribute("class", "text-danger");

      element.replaceChildren(child);

      return;
    }

    const { siteKey, theme, size } = this.props.reCaptcha;

    const onError = error =>
      setTimeout(() => {
        this.shouldMountReCaptcha() && this.renderReCaptcha(level + 1, error);
      }, 300);

    // https://developers.google.com/recaptcha/docs/display#javascript_api

    try {
      if (!this.reCaptchaRendered) {
        window.grecaptcha.render(this.state.reCaptcha.container.current, {
          sitekey: siteKey,
          theme,
          size: size || (window.innerWidth > 640 ? "normal" : "compact"),
          callback: token =>
            this.setState(
              {
                ...this.state,
                reCaptcha: {
                  ...this.state.reCaptcha,
                  token,
                  state: FormValidation.RECAPTCHA_VALIDATED
                }
              },
              this.state.reCaptcha.onValidated
            ),
          "expired-callback": onError,
          "error-callback": onError
        });

        this.reCaptchaRendered = true;
      }
    } catch (error) {
      debug(error, "error");

      onError(error);
    }
  }
}

FormValidation.propTypes = {
  setup: PropTypes.shape({
    ...TitleTextProps,
    fields: PropTypes.object.isRequired,
    placeholders: PropTypes.object
  }).isRequired,
  buttons: JSXProps(),
  className: PropTypes.string,
  reCaptcha: PropTypes.shape(GoogleReCaptchaProps()),
  i18n: PropTypes.object,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool
};

FormValidation.defaultProps = {
  className: FormValidationBS,
  reCaptcha: { disabled: true, theme: "light", size: "normal" }
};
