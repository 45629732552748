import { SEARCH_QUERY_PAGENO } from "@constants";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import { SpinnerBS } from "@style-variables";
import { shallowDeepCompare } from "@utils/array";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import BotAwareComponent from "./BotAwareComponent";

export default class InfiniteScrollComponent extends BotAwareComponent {
  // when false do not show any items on Bots, otherwise show all items (or the first chunk only, see SHOW_FIRST_CHUNK_ITEMS_ON_BOT)
  static SHOW_ALL_ITEMS_ON_BOT = true;

  // when true load only the first chunk of items on Bots, otherwise all (see SHOW_ALL_ITEMS_ON_BOT)
  static SHOW_FIRST_CHUNK_ITEMS_ON_BOT = false;

  constructor(props) {
    super(props);

    this.handleMoreItemsClick = this.handleMoreItemsClick.bind(this);
    this.handleObserver = this.handleObserver.bind(this);
    this.onNextChunk = this.onNextChunk.bind(this);

    const items = this.shouldDisable() ? this.props.items : [];

    this.state = { items };

    this.loading = false;
    this.prevY = 0;

    this.observer = new IntersectionObserver(this.handleObserver, {
      root: null,
      rootMargin: "0px",
      threshold: 0
    });

    this.targetRef = null;
  }

  /**
   * @description Checks whether the infinite scrolling should be used
   * @returns {Boolean}
   * @memberof InfiniteScrollComponent
   */
  shouldDisable() {
    return (
      !this.props.enabled ||
      (this.props.botDisabled && this.isBot) ||
      this.isTestBot
    );
  }

  componentDidMount() {
    if (!this.shouldDisable()) {
      this.loadNextChunk(
        this.props.infinite ? () => this.observeTarget(this.targetRef) : null
      );
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const shouldNotDisable = !this.shouldDisable();

    const showItemsOnBot =
      InfiniteScrollComponent.SHOW_ALL_ITEMS_ON_BOT ||
      InfiniteScrollComponent.SHOW_FIRST_CHUNK_ITEMS_ON_BOT;

    if ((showItemsOnBot && this.props.enabled) || shouldNotDisable) {
      if (shallowDeepCompare(this.props.items, prevProps.items)) {
        // reset the state
        this.setState({ ...this.state, items: [] }, () => {
          this.loadNextChunk(
            this.props.infinite
              ? () => this.observeTarget(this.targetRef)
              : null,
            shouldNotDisable ||
              !showItemsOnBot ||
              InfiniteScrollComponent.SHOW_FIRST_CHUNK_ITEMS_ON_BOT
              ? 0
              : this.props.items.length
          );
        });
      }
    }
  }

  /**
   * @description
   * @param {IntersectionObserverEntry} entries
   * @param {IntersectionObserver} observer
   * @memberof InfiniteScrollComponent
   */
  handleObserver(entries, observer) {
    const y = entries[0].boundingClientRect.y;

    if (this.prevY > y) {
      this.loadNextChunk();
    }

    this.prevY = y;
  }

  /**
   * @description Observe the intersection of target element with root document
   * @param {Element} target The element which intersection is to be observed
   * @memberof InfiniteScrollComponent
   */
  observeTarget(target) {
    if (target instanceof Element) {
      this.observer.observe(target);
    }
  }

  updateLocation(pageNo, params = {}) {
    const obj = { ...params };

    if (pageNo) {
      obj.pageNo = pageNo;
    }
  }

  onNextChunk({ i, batchSize, pageNo }) {
    if (!pageNo) {
      return;
    }

    const url = new URL(window.location);

    url.searchParams.set(SEARCH_QUERY_PAGENO, pageNo);

    window.history.replaceState(window.history.state, null, url);
  }

  /**
   * @description Load the next chunk of items
   * @param {function} [done=null] A callback that is triggered after loading next chunk
   * @param {number} [batchSize=0] The number of items to load (when not given then default batchSize)
   * @memberof InfiniteScrollComponent
   */
  loadNextChunk(done = null, batchSize = 0) {
    this.loading = true;

    const _batchSize = batchSize || this.props.batchSize;

    const pageNo =
      +(!this.state.items.length
        ? new URLSearchParams(window.location.search).get(SEARCH_QUERY_PAGENO)
        : "") || Math.ceil(this.state.items.length / _batchSize);

    const i = pageNo * _batchSize;

    const from = pageNo && !this.state.items.length ? 0 : i;

    const to = i + _batchSize;

    const items = this.state.items.concat(this.props.items.slice(from, to));

    this.setState({ items }, () => {
      this.loading = false;

      const cb =
        "function" === typeof this.props.onNextChunk
          ? this.props.onNextChunk
          : this.onNextChunk;

      cb({
        from,
        to,
        batchSize: _batchSize,
        pageNo
      });

      if ("function" === typeof done) {
        done();
      }
    });
  }

  /**
   * @description Handles the click of view more items button
   * @param {Event} e
   * @memberof InfiniteScrollComponent
   */
  handleMoreItemsClick(e) {
    this.loadNextChunk();
  }

  /**
   * @description Get the view more items button's caption
   * @returns {String}
   * @memberof InfiniteScrollComponent
   */
  getMoreItemsCaption() {
    return this.props.i18n.SHOW_MORE_ITEMS;
  }

  /**
   * @description Renders the view more items button
   * @returns {JSX}
   * @memberof InfiniteScrollComponent
   */
  renderMoreItemsButton() {
    if (this.state.items.length >= this.props.items.length) {
      return null;
    }

    const canSpin = this.loading;

    const spinner = canSpin ? (
      <Spinner
        as="span"
        size="sm"
        animation="border"
        role="status"
        aria-hidden={true}
        className={SpinnerBS}
      >
        <span className="sr-only">{this.props.i18n.LOADING_DATA}</span>
      </Spinner>
    ) : (
      this.getMoreItemsCaption()
    );

    const more = (
      <Button
        aria-label="Loading"
        variant="secondary"
        size="lg"
        className={getComponentClassName(
          this.props.className,
          "more",
          "px-5 mx-auto col-8 col-md-4"
        )}
        onClick={this.handleMoreItemsClick}
        ref={loadingRef => (this.targetRef = loadingRef)}
      >
        {spinner}
      </Button>
    );

    return (
      <Row>
        <Col xs="12" className="text-center mb-5">
          {more}
        </Col>
      </Row>
    );
  }
}

InfiniteScrollComponent.propTypes = {
  enabled: PropTypes.bool,
  botDisabled: PropTypes.bool,
  infinite: PropTypes.bool,
  batchSize: PropTypes.number.isRequired,
  className: PropTypes.string,
  onNextChunk: PropTypes.func,
  ...ItemsAwareProps
};

InfiniteScrollComponent.defaultProps = {
  batchSize: 1,
  infinite: true,
  enabled: true,
  botDisabled: true
};
