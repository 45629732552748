import { connectHOCs } from "@components-utils";
import { EVENT_SCROLL_TO_BOTTOM, EVENT_SCROLL_TO_TOP } from "@constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ScrollToTopBS } from "@style-variables";
import { scrollWithFallback } from "@utils/dom";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import PureComponent from "./PureComponent";

/**
 * @description A wrapper component for handling the scrolling of window
 * @export
 * @class ScrollToTop
 * @extends {PureComponent}
 */
export class ScrollToTop extends PureComponent {
  constructor(props) {
    super(props);

    this.scheduledAnimationFrame = false;

    if (!props.headless) {
      this.ref = React.createRef();
      this.handleOnWindowScroll = this.handleOnWindowScroll.bind(this);
    }

    this.handleScrollToTop = this.handleScrollToTop.bind(this);
    this.handleScrollToBottom = this.handleScrollToBottom.bind(this);
  }

  componentDidMount() {
    if (!this.props.headless) {
      window.addEventListener("scroll", this.handleOnWindowScroll, {
        passive: true
      });
    }

    // a custom event that will scroll the window to top when dispatched
    window.addEventListener(EVENT_SCROLL_TO_TOP, this.handleScrollToTop, {
      passive: true
    });

    // a custom event that will scroll the window to bottom when dispatched
    window.addEventListener(EVENT_SCROLL_TO_BOTTOM, this.handleScrollToBottom, {
      passive: true
    });
  }

  componentDidUpdate(prevProps) {
    // this will scroll the window to top ONLY on route change
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.handleScrollToTop();
    }
  }

  componentWillUnmount() {
    if (!this.props.headless) {
      window.removeEventListener("scroll", this.handleOnWindowScroll);
    }

    window.removeEventListener(EVENT_SCROLL_TO_TOP, this.handleScrollToTop);
    window.removeEventListener(
      EVENT_SCROLL_TO_BOTTOM,
      this.handleScrollToBottom
    );
  }

  /**
   * @description Handle the event of scrolliong the window. Useless while `headless`.
   * @param {Event} event
   * @memberof ScrollToTop
   */
  handleOnWindowScroll(event) {
    // optimize the screen repaint during scroll
    if (this.scheduledAnimationFrame) {
      return;
    }

    this.scheduledAnimationFrame = true;
    requestAnimationFrame(() => (this.scheduledAnimationFrame = false));

    const scrollBottom =
      /*document.body.scrollHeight -*/ document.body.clientHeight;
    const scrollTop = window.pageYOffset;

    const opacity = scrollTop / scrollBottom;

    if (opacity < 0.01) {
      this.ref.current.style.display = "none";
    } else {
      this.ref.current.style.opacity = opacity;
      this.ref.current.style.display = "block";
    }
  }

  /**
   * @description Scroll the window to top using the given scrolling behavior
   * @static
   * @param {Event} event
   * @param {string} [behavior="smooth"] Either `smooth` or `auto`
   * @memberof ScrollToTop
   */
  static scrollToTop(event, behavior = "smooth") {
    scrollWithFallback({ top: 0, left: 0, behavior });
  }

  /**
   * @description Scroll the window to bottom using the given scrolling behavior
   * @static
   * @param {Event} event
   * @param {string} [behavior="smooth"] Either `smooth` or `auto`
   * @memberof ScrollToTop
   */
  static scrollToBottom(event, behavior = "smooth") {
    scrollWithFallback({
      top: document.body.scrollHeight,
      left: 0,
      behavior
    });
  }

  /**
   * @description Scroll the window to top with auto scrolling behaviour
   * @param {Event} event
   * @memberof ScrollToTop
   */
  handleScrollToTop(event) {
    ScrollToTop.scrollToTop(event, (event || {}).detail || "smooth");
  }

  /**
   * @description Scroll the window to bottom with auto scrolling behaviour
   * @param {Event} event
   * @memberof ScrollToTop
   */
  handleScrollToBottom(event) {
    ScrollToTop.scrollToBottom(event, (event || {}).detail || "smooth");
  }

  render() {
    const scrollHandle = !this.props.headless ? (
      <div
        ref={this.ref}
        className={getComponentClassName(
          ScrollToTopBS,
          null,
          this.props.className
        )}
        title={this.props.title}
        onClick={ScrollToTop.scrollToTop}
        onKeyDown={ScrollToTop.scrollToTop}
        role="button"
        tabIndex="0"
      >
        <FontAwesomeIcon icon={this.props.icon} size="2x" />
      </div>
    ) : null;

    return (
      <React.Fragment>
        {scrollHandle}
        {this.props.children}
      </React.Fragment>
    );
  }
}

ScrollToTop.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  behavior: PropTypes.oneOf(["smooth", "auto"]),
  headless: PropTypes.bool
};

ScrollToTop.defaultProps = {
  title: "",
  icon: "chevron-up",
  behavior: "smooth",
  headless: false
};

export default connectHOCs(ScrollToTop, {
  withRouter: true
});
