import Media from "@components-core/Media";
import RouteLink from "@components-core/RouteLink";
import RibbonAwareComponent from "@components/Ribbon/RibbonAwareComponent";
import { shouldHideOverflow, transform } from "@components/Ribbon/utils";
import {
  PRODUCT_SELECTOR_TYPE_BRAND,
  PRODUCT_SELECTOR_TYPE_CATEGORY,
  PRODUCT_SELECTOR_TYPE_SERIES,
  RIBBON_SELECTION_ARTICLE_PAGE_ONLY
} from "@constants";
import GraphQLComponent from "@graphql-component";
import gqlProductCategories from "@graphql-query/productCategories.gql";
import gqlProductCategorySummaryFragment from "@graphql-query/productCategorySummaryFragment.gql";
import gqlProductImageFieldsFragment from "@graphql-query/productImageFieldsFragment.gql";
import gqlProductImageFragment from "@graphql-query/productImageFragment.gql";
import gqlProductSeries from "@graphql-query/productSeries.gql";
import gqlSEOScoreFragment from "@graphql-query/seoScoreFragment.gql";
import gqlTrademarks from "@graphql-query/trademarks.gql";
import {
  pushCategoryBreadcrumb,
  pushSubcategoryBreadcrumb
} from "@redux-actions/breadcrumbs";
import { ProductCategoryHeaderBS } from "@style-variables";
import { getCloudinaryUrl } from "@utils/cloudinary";
import {
  getSEOScoreDesc,
  getSEOScoreLevel,
  setCanonicalUrl
} from "@utils/functions";
import { escapeReact } from "@utils/react";
import { openGraphObjects } from "@utils/richdata";
import { joinNonEmptyStrings, stringToSlug } from "@utils/strings";
import React from "react";
import { Container } from "react-bootstrap";

const Subcategory = (subcategory, key) => {
  return (
    <RouteLink
      key={key}
      href={subcategory.searchKey}
      onClick={subcategory.onClick}
    >
      {subcategory.title}
    </RouteLink>
  );
};

const Subcategories = props => {
  return props.items && props.items.length ? (
    <Container className={props.className}>
      {props.items.map(Subcategory)}
    </Container>
  ) : null;
};

const ProductCategoryHeader = props => [
  <Media key={0} {...props} />,
  <Subcategories
    key={1}
    items={props.subcategories}
    className={joinNonEmptyStrings(
      ProductCategoryHeaderBS,
      "subcategories",
      "-"
    )}
    placeholder={props.placeholder}
  />
];

class SiteRouteProductCategoryHeader extends RibbonAwareComponent {
  constructor(props) {
    super(props);

    this.dataTransformer = this.dataTransformer.bind(this);
  }

  dataTransformer(data) {
    let key;

    switch (this.props.selectorType) {
      case PRODUCT_SELECTOR_TYPE_CATEGORY:
        key = "productCategories";
        break;
      case PRODUCT_SELECTOR_TYPE_BRAND:
        key = "trademarks";
        break;
      case PRODUCT_SELECTOR_TYPE_SERIES:
        key = "productSeries";
        break;
      default:
        throw new Error(
          `Unexpected value for selectorType = "${this.props.selectorType}"`
        );
    }

    const placeholderItems = [
      {
        summary: { images: [] },
        title: "0",
        text: "0",
        img: {
          aspect: 0.6,
          sizes: {
            mobilePortrait: 315,
            mobileLandscape: 480,
            tabletPortrait: 420,
            tabletLandscape: 300,
            desktop: 510
          }
        },
        itemsPerRow: 3
      }
    ];

    const obj = (data[key] || placeholderItems)[0];

    const item = obj
      ? {
          ...obj,
          text: escapeReact(obj.text, this.props.pathfinder)
        }
      : null;

    const category = [
      PRODUCT_SELECTOR_TYPE_BRAND,
      PRODUCT_SELECTOR_TYPE_SERIES
    ].includes(this.props.selectorType)
      ? this.props.imgBuilder(this.props.selectorType, item)
      : item;

    if (!category) {
      const path404 = this.props.pathfinder.get(
        this.props.httpErrors["404-product-category"]
      );

      this.props.history.push(path404, {
        key: this.props.match.params.categoryId
      });

      return null;
    }

    const id = joinNonEmptyStrings(
      ProductCategoryHeaderBS,
      category.id || null,
      "-"
    );

    category.helmet = {
      title: obj.title,
      meta: {
        name: {
          description: obj.text
          // keywords: obj.metaKeywords ? obj.metaKeywords : obj.title
        }
      }
    };

    // set the right canonical URL
    setCanonicalUrl(category.helmet, this.props.pathfinder, this.props.match, {
      categoryId: category.searchKey
    });

    const og = {
      title: category.helmet.title,
      description: obj.text,
      image: category.summary.images
        .concat({ src: category.img.src, cloudinary: category.img.cloudinary })
        .map(item =>
          getCloudinaryUrl({
            src: item.src,
            cloudinary: item.cloudinary,
            seoSuffix: category.helmet.title
              ? stringToSlug(category.helmet.title.toLowerCase())
              : category.helmet.title
          })
        ),
      site_name: this.props.siteTitle
    };

    // OpenGraph standard meta-tags
    category.helmet.meta.property = openGraphObjects({
      og,
      twitter: { site: "@username", card: "summary" }
    });

    // TODO : https://developers.google.com/search/docs/data-types/carousel
    // Structured data for Google search rich result
    // const googleRichResult = productStructuredData(
    //   {
    //     id: category.id,
    //     title: category.title,
    //     helmet: category.helmet,
    //     brand: category.summary.brand,
    //     images: category.summary.images,
    //     img: category.img,
    //     rating: category.summary.rating,
    //     review: category.summary.review,
    //     newPrice: category.summary.price,
    //     freight: category.summary.freight,
    //     currencyCode: category.summary.currencyCode,
    //     currencyPrefix: category.summary.currencyPrefix,
    //     currencySuffix: category.summary.currencySuffix,
    //     inStock: category.summary.inStock,
    //     count: category.summary.count
    //   },
    //   { [category.helmet.title]: null }
    // );

    // category.helmet.script = googleRichResult;

    const itemIncluded = array => array.some(i => +i === +obj.id);

    const ribbons = this.renderRibbons(ribbon => {
      if (PRODUCT_SELECTOR_TYPE_SERIES === this.props.selectorType) {
        return (
          ribbon.itemSelection !== RIBBON_SELECTION_ARTICLE_PAGE_ONLY &&
          itemIncluded(ribbon.article_id)
        );
      } else if (PRODUCT_SELECTOR_TYPE_CATEGORY === this.props.selectorType) {
        return itemIncluded(ribbon.category_id);
      } else if (PRODUCT_SELECTOR_TYPE_BRAND === this.props.selectorType) {
        return itemIncluded(ribbon.trademark_id);
      }
      return false;
    });

    // overflow=hidden and no padding is a must for corner ribbons
    const mediaImageClassName =
      this.state.ribbons && shouldHideOverflow(transform(this.state.ribbons))
        ? "overflow-hidden p-0"
        : null;

    this.props.pushCategoryBreadcrumb({
      title:
        PRODUCT_SELECTOR_TYPE_BRAND === this.props.selectorType
          ? this.props.match.params.categoryId.toUpperCase()
          : obj.title,
      href: window.location.pathname
    });

    const subcategories = (category.subcategories || []).map(subcategory => ({
      ...subcategory,
      onClick: e => {
        let href = subcategory.searchKey;

        if (-1 === subcategory.searchKey.indexOf("/")) {
          const routeName = this.props.pathfinder.find(this.props.match.path);
          href = this.props.pathfinder.generate(routeName, {
            categoryId: subcategory.searchKey
          });
        }

        this.props.pushSubcategoryBreadcrumb({
          title: subcategory.title,
          href,
          parent: window.location.pathname
        });
      }
    }));

    return {
      ...category,
      subcategories,
      id,
      className: joinNonEmptyStrings(
        ProductCategoryHeaderBS,
        getSEOScoreLevel(category.seo),
        " "
      ),
      disabled: !category.text,
      as: "h1",
      loading: "eager",
      preload: true,
      "data-id": obj.id,
      "data-seo-desc": getSEOScoreDesc(category.seo),
      mediaImageClassName,
      ribbons
    };
  }

  gqlQuery(selectorType) {
    const result = [
      gqlProductImageFragment,
      gqlProductCategorySummaryFragment,
      gqlProductImageFieldsFragment,
      gqlSEOScoreFragment
    ];
    switch (selectorType) {
      case PRODUCT_SELECTOR_TYPE_CATEGORY:
        result.unshift(gqlProductCategories);
        return result;
      case PRODUCT_SELECTOR_TYPE_BRAND:
        result.unshift(gqlTrademarks);
        return result;

      case PRODUCT_SELECTOR_TYPE_SERIES:
        result.unshift(gqlProductSeries);
        return result;
      default:
        throw new Error(
          `Unexpected value for selectorType = "${selectorType}"`
        );
    }
  }

  gqlVarName(selectorType) {
    switch (selectorType) {
      case PRODUCT_SELECTOR_TYPE_CATEGORY:
        return "categoryId";
      case PRODUCT_SELECTOR_TYPE_BRAND:
        return "trademarkId";
      case PRODUCT_SELECTOR_TYPE_SERIES:
        return "serieId";
      default:
        throw new Error(
          `Unexpected value for selectorType = "${selectorType}"`
        );
    }
  }

  render() {
    const gqlProps = {
      graphqlClient: this.props.graphqlClient,
      query: this.gqlQuery(this.props.selectorType),
      variables: {
        siteId: this.props.siteId
      },
      dataTransformer: this.dataTransformer,
      wraps: ProductCategoryHeader
    };

    const categoryId = +this.props.match.params.categoryId;

    const selectorType = this.props.selectorType;

    // if the given route param is an integer, search by search-id
    if (Number.isInteger(categoryId)) {
      gqlProps.variables[this.gqlVarName(selectorType)] = categoryId;
    }
    // otherwise search by search-key
    else {
      gqlProps.variables.searchKey = this.props.match.params.categoryId;
    }

    return <GraphQLComponent {...gqlProps} />;
  }
}

SiteRouteProductCategoryHeader.defaultProps = {
  ...RibbonAwareComponent.defaultProps,
  title: "",
  text: "",
  className: "img-fluid"
};

// ------------------- REDUX ----------------------

SiteRouteProductCategoryHeader.mapStateToProps = (state, ownProps) => {
  const compareResult = state.compareProductsResult;

  return {
    ...RibbonAwareComponent.mapStateToProps(state, ownProps),
    hasCompareResult:
      !compareResult.isFetching &&
      !compareResult.error &&
      compareResult.items.length > 1
  };
};

SiteRouteProductCategoryHeader.mapDispatchToProps = {
  ...RibbonAwareComponent.mapDispatchToProps,
  pushCategoryBreadcrumb,
  pushSubcategoryBreadcrumb
};

SiteRouteProductCategoryHeader.mapValueToProps = value => ({
  ...RibbonAwareComponent.mapValueToProps(value),
  httpErrors: value.httpErrors,
  imgBuilder: (selectorType, item) =>
    item
      ? PRODUCT_SELECTOR_TYPE_BRAND === selectorType
        ? {
            id: item.id,
            img: {
              src: value.imgResolver(value.imgDir, item.imgSrc),
              cloudinary: value.cloudinary,
              sizes: { any: 200 },
              aspect: 0.17
            },
            url: item.url,
            title: item.title,
            text: item.text,
            summary: item.summary,
            searchKey: item.searchKey
          }
        : PRODUCT_SELECTOR_TYPE_SERIES === selectorType
        ? {
            ...item,
            img: {
              ...item.img,
              sizes: {
                desktop: 510,
                mobileLandscape: 480,
                mobilePortrait: 315,
                tabletLandscape: 300,
                tabletPortrait: 420
              },
              aspect: (item.img || {}).aspect || 0.6
            }
          }
        : item
      : null,
  siteTitle: value.helmet.title || value.title
});

export default SiteRouteProductCategoryHeader.connectHOC;
