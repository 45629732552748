import { connectHOCs } from "@components-utils";
import GraphQLComponent from "@graphql-component";
import { SORT_ASC } from "@graphql-operators";
import gqlProductCategorySummaryFragment from "@graphql-query/productCategorySummaryFragment.gql";
import gqlProductImageFieldsFragment from "@graphql-query/productImageFieldsFragment.gql";
import gqlProductImageFragment from "@graphql-query/productImageFragment.gql";
import gqlProductSeries from "@graphql-query/productSeries.gql";
import gqlSEOScoreFragment from "@graphql-query/seoScoreFragment.gql";
import { prefixRoute } from "@utils/route";
import React from "react";
import ProductSerieList from "./List";

const ProductSerieListComponent = props => {
  const gqlProps = {
    graphqlClient: props.graphqlClient,
    query: [
      gqlProductSeries,
      gqlProductImageFragment,
      gqlProductCategorySummaryFragment,
      gqlProductImageFieldsFragment,
      gqlSEOScoreFragment
    ],
    variables: {
      siteId: props.siteId,
      orderBy: props.graphqlClient.sortOrderInput("orderIndex", SORT_ASC)
    },
    dataTransformer: data => {
      const items = data.productSeries || [];

      return {
        items: items.map(serie => ({
          ...serie,
          url: prefixRoute(serie.url)
        })),
        horizontal: props.horizontal
      };
    },
    wraps: ProductSerieList
  };

  return <GraphQLComponent {...gqlProps} />;
};

ProductSerieListComponent.defaultProps = {};

export default connectHOCs(ProductSerieListComponent, {
  withSite: true,
  withGraphQL: true
});
