/**
 * @description Extract the parent directory name of a file
 * @param {String} filename The filename
 * @param {string} [separator="/"] The path separator
 * @returns {String} Returns the parent directory name
 */
function dirName(filename, separator = "/") {
  return filename.replace(/(^\/.+)\/$/).replace(/(.*)\/[^/]+$/) || separator;
}

/**
 * @description Extract the base name of a filename
 * @param {String} filename
 * @param {Boolean} [exclExtension = false] When true extract the base name excluding the file extension
 * @param {String} [separator = "/"] The path separator
 * @returns {String}
 */
function baseName(filename, exclExtension = false, separator = "/") {
  const re = new RegExp(`.*\\${separator}`, "g");

  const result = filename ? filename.replace(re, "") : "";

  return exclExtension ? stripExtension(result) : result;
}

/**
 * @description Strip the extension part of a filename
 * @param {String} filename The filename
 * @returns {String} The filename without the extension
 */
function stripExtension(filename) {
  const index = filename.lastIndexOf(".");
  return index > -1 ? filename.slice(0, index) : filename;
}

/**
 * @description Removes the trailing slash from path
 * @param {String} path The path
 * @param {String} [separator="/"] The path separator
 * @returns {String}
 */
function removeTrailingSlash(path, separator = "/") {
  if (path.slice(-1) === separator) {
    return path.slice(0, -1);
  }

  return path;
}

/**
 * @description Get the extension part of a filename
 * @param {String} filename The filename
 * @param {boolean} [includeDot=true] When true then include the dot, otherwise strip it
 * @returns {String} The filename's extension
 */
function getExtension(filename, includeDot = true) {
  const index = filename.lastIndexOf(".");
  return index > -1 ? filename.slice(index + +!includeDot) : "";
}

/**
 * @description Check whether the given filename has a given file extension
 * @param {String} filename The filename
 * @param {String} ext The checked file extension
 * @returns {boolean} Returns true if the filename has the given extension, false otherwise
 */
function fileHasExtension(filename, ext) {
  return ext.toUpperCase() === getExtension(filename, false).toUpperCase();
}

export {
  dirName,
  baseName,
  stripExtension,
  getExtension,
  fileHasExtension,
  removeTrailingSlash
};
