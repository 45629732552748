import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import {
  initBreadcrumbs,
  pushPageBreadcrumb
} from "@redux-actions/breadcrumbs";
import { removeTrailingSlash } from "@utils/filesystem";
import { escapeRegExp } from "@utils/strings";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import PageBreadcrumb from "../Page/Breadcrumb";
import PageTemplate from "../Page/Template";

const Root = props => {
  const { screen, siteId, children, homePath, breadcrumbs } = props;

  const dispatch = useDispatch();
  const pathname = removeTrailingSlash(useLocation().pathname);

  const breadcrumbItems = useSelector(state => {
    const length = breadcrumbs ? state.breadcrumbs.items.length : 0;
    if (length) {
      return [
        {
          title: window.location.hostname || homePath,
          href: homePath,
          isHome: true
        },
        ...state.breadcrumbs.items.map((item, i) => ({
          ...item,
          active: i === length - 1
        }))
      ];
    }
    return [];
  });

  const items = [].concat(screen.items);

  useEffect(() => {
    const re = new RegExp(
      `^${escapeRegExp(homePath)}\\/product(-(category|series|brand))?\\/`
    );

    // reset breadcrumbs
    if (pathname === homePath) {
      dispatch(initBreadcrumbs());
    }
    // push page breadcrum
    else if (!re.test(pathname)) {
      const breadcrumb = {
        title:
          ((screen.helmet.meta || { name: {} }).name || {}).breadcrumb ||
          screen.helmet.title,
        href: pathname
      };

      dispatch(pushPageBreadcrumb(breadcrumb));
    }
  }, [dispatch, screen.helmet.meta, screen.helmet.title, pathname, homePath]);

  const result = [
    <PageBreadcrumb items={breadcrumbItems} key={0} />,
    <PageTemplate
      key={1}
      siteId={siteId}
      template={{
        items: items,
        lazy: screen.lazy,
        role: screen.role,
        features: screen.features
      }}
      id={screen.id}
      className={screen.className}
      style={screen.style}
      onClick={screen.onClick}
    />,
    children
  ];

  return result;
};

export default Root;

Root.propTypes = {
  siteId: PropTypes.number.isRequired,
  screen: PropTypes.shape({
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    helmet: PropTypes.object,
    ...ItemsAwareProps
  }),
  homePath: PropTypes.string,
  breadcrumbs: PropTypes.bool
};

Root.defaultProps = { breadcrumbs: true };
