import { withPlaceholder } from "@components-core/Placeholder";
import { connectHOCs } from "@components-utils";
import IProductAvailability from "@prop-types/AvailabilityProps";
import { ProductAvailabilityBS } from "@style-variables";
import { isProductAvailable } from "@utils/functions";
import { getComponentClassName } from "@utils/strings";
import React from "react";

const ProductAvailability = props => {
  if (props.validFrom && !isProductAvailable(props)) {
    return (
      <React.Fragment>
        <span
          className={getComponentClassName(ProductAvailabilityBS, "from-title")}
        >
          {`${props.i18n.components.ProductAvailability.LABEL_AVAILABILITY_FROM}: `}
        </span>
        <span
          className={getComponentClassName(ProductAvailabilityBS, "from-date")}
        >
          {withPlaceholder(props.placeholder, props.validFrom)}
        </span>
      </React.Fragment>
    );
  }

  return null;
};

ProductAvailability.propTypes = IProductAvailability;

export default connectHOCs(ProductAvailability, { withSite: true });
