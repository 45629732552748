import { shallowDeepCompare } from "./array";
import { prefetch, preload } from "./dom";
import PictureHelper from "./picture";
import { hashValue, stringToSlug } from "./strings";
import { actualDeviceBreakpoint } from "@utils/breakpoints";

/**
 * @description Helper class that aids in preloading/prefetching image assets
 * @export
 * @class PreloadHelper
 */
export default class PreloadHelper {
  constructor(props) {
    this.props = null;
    this.prevProps = null;

    this.actualBreakpoint = actualDeviceBreakpoint();

    this.setProps(props);

    this.helper = new PictureHelper();
  }

  /**
   * @description Set the properties
   * @param {Object} props
   * @memberof PreloadHelper
   */
  setProps(props) {
    this.prevProps = this.props;
    this.props = props
      ? {
          ...props,
          preloadCount: +props.preloadCount,
          prefetchCount: +props.prefetchCount
        }
      : props;
  }

  /**
   * @description Get the link ID for the given image/source
   * @param {Object} defaultImage
   * @param {Object} source
   * @returns {String}
   * @memberof PreloadHelper
   */
  getLinkId(defaultImage, source) {
    const str = [
      defaultImage.src,
      defaultImage.title || defaultImage.alt,
      defaultImage.loading,
      defaultImage.importance,
      source.media || source.sizes
    ]
      .filter(Boolean)
      .map(stringToSlug)
      .join("_")
      .toLowerCase();

    return "id_" + hashValue(str);
  }

  /**
   * @description Schedules the given prelaod/prefetch resolver for the given image assets
   * @param {Array} items The image assets
   * @param {Function} resolver Either the preload|prefetch function
   * @memberof PreloadHelper
   */
  updateFetchHints(items, resolver) {
    items.forEach(item => {
      const sources =
        item.sources && item.sources.length
          ? item.sources
          : [
              {
                srcSet: item.defaultImage.src
              }
            ];

      sources.forEach(source => {
        resolver(
          this.getLinkId(item.defaultImage, source),
          source.srcSet,
          "image",
          source.media,
          true
        );
      });
    });
  }

  /**
   * @description Extracts the item's image asset properties
   * @param {Object} item The image item
   * @returns {Object}
   * @memberof PreloadHelper
   */
  getImageProps(item) {
    let imgProps = { title: item.title, loading: item.loading };

    if (typeof item.img === "string") {
      imgProps.src = item.img;
    }
    if (typeof item.img === "object") {
      imgProps = {
        ...imgProps,
        ...item.img
      };
    }

    return imgProps;
  }

  /**
   * @description Get the data based on which the image assets are preloaded/prefetched
   * @returns {Array}
   * @memberof PreloadHelper
   */
  getData() {
    if (!(this.props.prefetch || this.props.preload)) {
      return null;
    }

    return this.props.items
      .map(item => {
        const imgProps = this.getImageProps(item);

        if (!imgProps.src) {
          return null;
        }

        this.helper.setProps({
          ...imgProps,
          style: {
            minHeight:
              this.props.minHeight ||
              (imgProps.sizes || {})[this.actualBreakpoint],
            maxHeight: this.props.maxHeight
          }
        });

        return this.helper.getProps();
      })
      .filter(Boolean);
  }

  /**
   * @description Mounts the image assets in the head
   * @memberof PreloadHelper
   */
  mountLinks() {
    const shouldPreload = this.props.preload && this.props.preloadCount;
    const shouldPrefetch =
      this.props.prefetch && (this.props.prefetchCount || shouldPreload);

    if (
      (shouldPreload || shouldPrefetch) &&
      shallowDeepCompare(this.prevProps, this.props)
    ) {
      this.prevProps = this.props;

      const data = this.getData();

      if (shouldPreload) {
        this.updateFetchHints(data.slice(0, this.props.preloadCount), preload);
      }

      if (shouldPrefetch) {
        const prefetchFrom = this.props.preloadCount * +shouldPreload;
        const prefetchTo =
          1 +
          prefetchFrom +
          (this.props.prefetchCount || this.props.preloadCount);

        this.updateFetchHints(data.slice(prefetchFrom, prefetchTo), prefetch);
      }
    }
  }
}
