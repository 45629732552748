import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Media, Row } from "react-bootstrap";
import { ContactEmailBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import ContactEmailProps from "@prop-types/ContactEmailProps";

const ContactEmail = ({ visibleOnPage, email, text }) => {
  if (!visibleOnPage) {
    return null;
  }

  return (
    <Media className={getComponentClassName(ContactEmailBS)} as={Row}>
      <Col xs="2" className="text-center">
        <a href={`mailto:${email}`} title={email}>
          <FontAwesomeIcon icon="envelope" size="3x" />
        </a>
      </Col>
      <Col xs="10">
        <Media.Body>
          <p className="h4">
            <a href={`mailto:${email}`}>{email}</a>
          </p>
          <p>{text}</p>
        </Media.Body>
      </Col>
    </Media>
  );
};

export default ContactEmail;

ContactEmail.propTypes = {
  ...ContactEmailProps
};
