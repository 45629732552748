import BaseButton from "@components-core/BaseButton";
import ImageCarousel from "@components-core/ImageCarousel";
import Picture from "@components-core/Picture";
import PureComponent from "@components-core/PureComponent";
import RouteLink from "@components-core/RouteLink";
import { carouselOptimalDelayFactor } from "@components-utils";
import BaseButtonProps from "@prop-types/BaseButtonProps";
import { CampaignBS } from "@style-variables";
import { getMinBreakpoint } from "@utils/breakpoints";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Nav, Row } from "react-bootstrap";
import ImageLoadingType from "../prop-types/ImageLoadingType";

export default class Promotion extends PureComponent {
  renderBtn(item, index) {
    return (
      <Col
        md="6"
        xs="6"
        key={index}
        className={getComponentClassName(CampaignBS, "button-" + index)}
      >
        <BaseButton {...item.button} title={item.title} icon={item.icon} />
      </Col>
    );
  }

  renderButtons() {
    const buttons = this.props.buttons.map(this.renderBtn);

    if (!buttons.length) {
      return null;
    }

    return (
      <Row className={getComponentClassName(CampaignBS, "buttons")}>
        {buttons}
      </Row>
    );
  }

  renderImg(item, colProps = {}, linkProps = {}) {
    let alt = item.alt ? item.alt : item.title;

    let imgProps = { title: alt };

    if (typeof item.img === "string") {
      imgProps.src = item.img;
    }

    if (typeof item.img === "object") {
      imgProps = { ...imgProps, ...item.img };
      if (!alt) {
        alt = item.img.alt ? item.img.alt : item.img.title;
      }
    }

    const style = {};

    if (item.img.sizes) {
      // const sizes = Object.values(item.img.sizes).filter(Boolean);
      // const minWidth = Math.min(...sizes);
      const minWidth = getMinBreakpoint(item.img.sizes);

      const aspect = imgProps.aspect || 1;

      style.minHeight = aspect * minWidth + "px";
    }

    return (
      <Col {...colProps}>
        <RouteLink to={item.href} title={alt}>
          <Nav.Link as="div" href={item.href} className="px-0 h-100 text-center">
            <Picture {...imgProps} style={style} />
          </Nav.Link>
        </RouteLink>
      </Col>
    );
  }

  renderCarousel() {
    const items = this.props.carousel.map((item, i) => ({
      img: item.img,
      url: item.href,
      loading: item.loading || (i ? this.props.loading : "eager")
    }));

    const minHeight = items.map(item => {
      //  const sizes = Object.values(item.img.sizes || {}).filter(Boolean);
      //  const minWidth = Math.min(...sizes);
      const minWidth = getMinBreakpoint(item.img.sizes);

      const aspect = item.img.aspect || 1;

      return aspect * minWidth;
    });

    const delayFactor = this.props.intervalDelay
      ? carouselOptimalDelayFactor()
      : 0;

    return (
      <ImageCarousel
        minHeight={Math.max(...minHeight)}
        items={items}
        className={getComponentClassName(CampaignBS, "carousel")}
        indicators={true}
        controls={false}
        magnifier={false}
        prevIcon={null}
        nextIcon={null}
        interval={this.props.interval}
        intervalDelay={delayFactor * this.props.intervalDelay}
      />
    );
  }

  renderImages() {
    return this.props.rightImg.map((item, i) =>
      this.renderImg(item, {
        className: getComponentClassName(CampaignBS, "right-image"),
        key: i
      })
    );
  }

  renderHeader() {
    const header = this.props.header;

    const rows = [];

    if (header) {
      if (header.title) {
        rows.push(
          <Row
            className={getComponentClassName(CampaignBS, "header-title")}
            key={rows.length}
          >
            <Col>{header.title}</Col>
          </Row>
        );
      }
      if (header.text) {
        rows.push(
          <Row
            className={getComponentClassName(CampaignBS, "header-text")}
            key={rows.length}
          >
            <Col>{header.text}</Col>
          </Row>
        );
      }
    }

    return rows.length ? (
      <div className={getComponentClassName(CampaignBS, "header")}>{rows}</div>
    ) : null;
  }

  render() {
    const header = this.renderHeader();
    const images = this.renderImages();
    const buttons = this.renderButtons();

    return (
      <Container className={getComponentClassName(CampaignBS)}>
        {header}
        <Row className={getComponentClassName(CampaignBS, "images")}>
          <Col md="8">{this.renderCarousel()}</Col>
          <Col md="4">
            <Container
              className={getComponentClassName(CampaignBS, "right", "px-0")}
            >
              {images}
            </Container>
          </Col>
        </Row>

        {buttons}
      </Container>
    );
  }
}

const imgShape = {
  img: PropTypes.object.isRequired,
  href: PropTypes.string
};

Promotion.propTypes = {
  interval: PropTypes.number,
  intervalDelay: PropTypes.number,
  header: PropTypes.object,
  carousel: PropTypes.arrayOf(PropTypes.shape(imgShape)).isRequired,
  rightImg: PropTypes.arrayOf(PropTypes.shape(imgShape)).isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      button: PropTypes.shape(BaseButtonProps()).isRequired,
      title: PropTypes.string,
      icon: PropTypes.object
    })
  ).isRequired,
  loading: ImageLoadingType()
};

Promotion.defaultProps = {
  intervalDelay: 3000,
  interval: 5000,
  loading: "lazy"
};
