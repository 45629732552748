import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { TagButtonBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import ColorVariantType from "@prop-types/ColorVariantType";

const TagButton = React.memo(function TagButton(props) {
  const className = props.className || TagButtonBS;

  return (
    <ButtonGroup
      aria-label={props.id}
      className={getComponentClassName(className, "item")}
      style={props.style}
    >
      <Button
        id={props.id}
        variant={props.variant}
        onClick={props.onRemoveClick}
        size="sm"
        title={props.removeTitle}
        className={getComponentClassName(className, "item-remove")}
        data-active={props.active}
      >
        <FontAwesomeIcon icon="backspace" />
      </Button>
      <Button
        variant="outline-secondary"
        title={props.title}
        size="sm"
        disabled
        className={getComponentClassName(className, "item-label")}
      >
        {props.title}
      </Button>
    </ButtonGroup>
  );
});

TagButton.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  onRemoveClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  removeTitle: PropTypes.string,
  active: PropTypes.bool,
  variant: ColorVariantType(),
  style: PropTypes.object
};

TagButton.defaultProps = {
  variant: "primary"
};

export default TagButton;
