import ModalDialog from "@components-core/ModalDialog";
import PureComponent from "@components-core/PureComponent";
import { connectHOCs } from "@components-utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ShoppingCartItemProps from "@prop-types/ShoppingCartItemProps";
import ShoppingCartItemSetupProps from "@prop-types/ShoppingCartItemSetupProps";
import {
  cartDecrement,
  cartIncrement,
  cartRemoveProduct
} from "@redux-actions/cart";
import { IconAlignRight, ShoppingCartItemBS } from "@style-variables";
import { dangerouslySetInnerHTML } from "@utils/react";
import { formatCurrency, getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import LinkItem from "../Link/Item";

class ShoppingCartItem extends PureComponent {
  constructor(props) {
    super(props);

    this.handleOnIncrementQtyClick = this.handleOnIncrementQtyClick.bind(this);
    this.handleOnDecrementQtyClick = this.handleOnDecrementQtyClick.bind(this);

    this.handleConfirmItemRemoval = this.handleConfirmItemRemoval.bind(this);
    this.handleCancelItemRemoval = this.handleCancelItemRemoval.bind(this);

    this.state = { increase: false, decrease: false, id: null };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isCalculating && !this.props.isCalculating) {
      this.setState({ increase: false, decrease: false, id: null });
    }
  }

  handleOnIncrementQtyClick(e) {
    this.setState({ increase: true, id: this.props.id });
    this.props.cartIncrement(this.props.cartItemIndex, this.props.siteConfig);
  }

  handleOnDecrementQtyClick(e) {
    this.setState({ decrease: true, id: this.props.id });
    this.props.cartDecrement(this.props.cartItemIndex, this.props.siteConfig);
  }

  handleCancelItemRemoval(e) {
    this.props.cartIncrement(this.props.cartItemIndex, this.props.siteConfig);
  }

  handleConfirmItemRemoval(e) {
    this.props.cartRemoveProduct(
      this.props.cartItemIndex,
      this.props.siteConfig
    );
  }

  /*getModalBody() {
    return (
      <p>{this.props.i18n.components.ShoppingCart.ITEM_CONFIRM_REMOVAL_BODY}</p>
    );
  }*/

  renderConfirmRemoveItemModalDialog() {
    const buttons = [
      {
        variant: "secondary",
        onClick: this.handleCancelItemRemoval,
        title:
          this.props.i18n.components.ShoppingCart
            .ITEM_CONFIRM_REMOVAL_BTN_CANCEL
      },
      {
        align: IconAlignRight,
        variant: "danger",
        onClick: this.handleConfirmItemRemoval,
        title:
          this.props.i18n.components.ShoppingCart.ITEM_CONFIRM_REMOVAL_BTN_OK
      }
    ];

    const message =
      this.props.i18n.components.ShoppingCart.ITEM_CONFIRM_REMOVAL_BODY.replace(
        "%product%",
        `<span class="font-weight-bolder">${this.props.title}</span>`
      );

    const body = dangerouslySetInnerHTML(message, "p");

    return (
      <ModalDialog
        title={
          this.props.i18n.components.ShoppingCart.ITEM_CONFIRM_REMOVAL_TITLE
        }
        body={body}
        className="centered"
        centered={false}
        backdrop={true}
        buttons={buttons}
        show={this.props.showModal}
      />
    );
  }

  render() {
    const name = this.props.title; //+(this.props.subtitle ? `(${this.props.subtitle})` : "");

    const calcCss = (state = true) =>
      state && this.props.isCalculating && this.state.id === this.props.id
        ? "calculating "
        : "";

    const decrementQty = this.props.disabled ? null : (
      <InputGroup.Prepend
        onClick={
          this.props.quantity <= 0 ? null : this.handleOnDecrementQtyClick
        }
      >
        <InputGroup.Text
          className={
            calcCss(this.state.decrease) +
            (this.props.quantity <= 0 ? "disabled" : "")
          }
        >
          <FontAwesomeIcon icon="minus" size="sm" />
        </InputGroup.Text>
      </InputGroup.Prepend>
    );

    const incrementQty = this.props.disabled ? null : (
      <InputGroup.Append
        onClick={this.props.disabled ? null : this.handleOnIncrementQtyClick}
      >
        <InputGroup.Text className={calcCss(this.state.increase)}>
          <FontAwesomeIcon icon="plus" size="sm" />
        </InputGroup.Text>
      </InputGroup.Append>
    );

    let linkItem = (
      <LinkItem
        img={this.props.img}
        url={this.props.href}
        title={name}
        className="px-0"
        style={{
          maxWidth: this.props.setup.imgSize
            ? this.props.setup.imgSize.maxWidth
            : null,
          maxHeight: this.props.setup.imgSize
            ? this.props.setup.imgSize.maxHeight
            : null
        }}
      />
    );

    return (
      <React.Fragment>
        <Container
          className={getComponentClassName(
            ShoppingCartItemBS,
            null,
            this.props.quantity <= 0 ? "text-danger" : null
          )}
        >
          <Row>
            {/* LEFT IMAGE */}
            <Col
              md="6"
              sm="12"
              xs="12"
              lg="3"
              className={getComponentClassName(
                ShoppingCartItemBS,
                "thumbnail",
                "px-0"
              )}
            >
              {linkItem}
            </Col>
            {/* DESCRIPTION, QTY, PRICE, TOTAL */}
            <Col
              md="6"
              sm="12"
              xs="12"
              lg="9"
              className={getComponentClassName(
                ShoppingCartItemBS,
                "description"
              )}
            >
              <Container>
                <Row>
                  {/* DESCRIPTION */}
                  <Col
                    md="12"
                    sm="12"
                    xs="12"
                    lg="6"
                    className={getComponentClassName(
                      ShoppingCartItemBS,
                      "name",
                      "mb-3 px-0"
                    )}
                  >
                    <label className="d-block font-weight-bold">
                      {this.props.setup.name}
                    </label>
                    <LinkItem
                      caption={name}
                      url={this.props.href}
                      style={{ padding: 0 }}
                    />
                  </Col>
                  {/* QUANTITY */}
                  <Col
                    md="5"
                    sm="4"
                    xs="5"
                    lg="2"
                    className={getComponentClassName(
                      ShoppingCartItemBS,
                      "quantity",
                      "text-center px-1"
                    )}
                  >
                    <Form.Group>
                      <Form.Label className="font-weight-bold">
                        {this.props.setup.quantity}
                      </Form.Label>
                      <InputGroup>
                        {decrementQty}
                        <Form.Control
                          required
                          size="sm"
                          min="0"
                          disabled={true}
                          value={this.props.quantity}
                          className={calcCss() + "text-center"}
                          aria-label={this.props.setup.quantity}
                        />
                        {incrementQty}
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  {/* PRICE */}
                  <Col
                    md="3"
                    sm="4"
                    xs="3"
                    lg="2"
                    className={getComponentClassName(
                      ShoppingCartItemBS,
                      "price",
                      "text-right px-1"
                    )}
                  >
                    <label className="d-block font-weight-bold">
                      {this.props.setup.price}
                    </label>
                    <span className="d-block">
                      {formatCurrency(
                        this.props.newPrice,
                        this.props.currencyPrefix,
                        this.props.currencySuffix
                      )}
                    </span>
                  </Col>
                  {/* AMOUNT */}
                  <Col
                    md="4"
                    sm="4"
                    xs="4"
                    lg="2"
                    className={getComponentClassName(
                      ShoppingCartItemBS,
                      "amount",
                      "text-right px-1"
                    )}
                  >
                    <label className="d-block font-weight-bold">
                      {this.props.setup.amount}
                    </label>
                    <span className={calcCss() + "d-block"}>
                      {formatCurrency(
                        this.props.amount,
                        this.props.currencyPrefix,
                        this.props.currencySuffix
                      )}
                    </span>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        {this.renderConfirmRemoveItemModalDialog()}
      </React.Fragment>
    );
  }
}

ShoppingCartItem.propTypes = {
  ...ShoppingCartItemProps,
  setup: PropTypes.shape(ShoppingCartItemSetupProps)
};

ShoppingCartItem.mapStateToProps = (state, ownProps) => ({
  quantity: state.cart.items[ownProps.cartItemIndex].quantity,
  cartItemIndex: ownProps.cartItemIndex,
  showModal: state.cart.items[ownProps.cartItemIndex].quantity === 0,
  isCalculating: state.calculatorResult.isFetching
});

ShoppingCartItem.mapDispatchToProps = {
  cartIncrement,
  cartDecrement,
  cartRemoveProduct
};

export default connectHOCs(ShoppingCartItem, {
  withSite: true,
  withConnect: true,
  withGraphQL: true
});
