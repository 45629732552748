import React from "react";
import Placeholder from "@components-core/Placeholder";
import { connectHOCs } from "@components-utils";
import {
  PRODUCT_SELECTOR_TYPE_FAVORITE,
  PRODUCT_SELECTOR_TYPE_SEARCH_RESULT
} from "@constants";
import GraphQLComponent from "@graphql-component";
import gqlProductFilterSetup from "@graphql-query/productFilterSetup.gql";
import gqlProductsFilterSetup from "@graphql-query/productsFilterSetup.gql";
import { getSearchOptions } from "@redux-actions/utils";
import { joinNonEmptyStrings } from "@utils/strings";
import ProductFilterCategory from "../ProductFilter/Category";
import ProductFilterCheckboxInput from "../ProductFilter/CheckboxInput";
import RangeFilterInput from "../ProductFilter/RangeInput";
import ProductCategorySelector from "./Selector";

/**
 * When TRUE then the filters are applied on changed. Otherwise only by click event (BUTTON_APPLY_FILTER)
 */
const AUTO_FILTERING = true;

/**
 * @description Checks whether the server-provided layout item was defined as visible/selectable
 * @param {Object} item The server-provided layout item
 * @returns {Boolean} Returns true if the given item can be selected or not (thus hidden/filtered)
 */
const propIsSelected = item => {
  const isSelected = item =>
    !AUTO_FILTERING || "undefined" === typeof item.selected || String;

  switch (item.as) {
    case "composite":
    case "placeholder":
      return true;
    case "range":
    case "checkbox":
      return isSelected(item.props);
    default:
      return true;
  }
};

/**
 * @description Transforms the server-provided layout item `as` property to a client-specific component classname
 * @param {Object} item The server-provided layout item
 * @param {Object} props Passthrough the props object
 * @returns {Object} The transformer layout item
 * @example before `as : "range"` , after `as : RangeFilterInput`
 */
const filterPropsTransformer = (item, props) => {
  switch (item.as) {
    case "range":
      item.as = RangeFilterInput;
      item.props.detail = item.props.detail.map(({ value, items }) => ({
        value: +value,
        items
      }));
      item.props.detailCountLabel =
        props.i18n.components.ProductCategorySelector.histogram.BIN_TOOLTIP;
      break;
    case "composite":
      item.as = ProductFilterCategory;
      item.props.items = item.props.items
        .filter(propIsSelected)
        .map(item => filterPropsTransformer(item, props));
      break;
    case "checkbox":
      item.as = ProductFilterCheckboxInput;
      break;
    case "placeholder":
      item.as = Placeholder;
      break;
    default:
      break;
  }

  return item;
};

/**
 * @description Transforms the server-provided end-component `id` property value to a nested `id` dash-separated property value
 * @param {Object} item The server-provided layout item
 * @param {String} [parentId=null] The item parent Id, if any
 * @returns {Object} The transformer layout item
 * @example before `id : "90cm"` after `id: "measurement-depth-90cm"`
 */
const nestedIdTransformer = (item, parentId = null) => {
  if (item.props) {
    if (item.props.id) {
      item.props.id = joinNonEmptyStrings(parentId, item.props.id);
    }

    if (item.props.items) {
      item.props.items = item.props.items.map(subItem =>
        nestedIdTransformer(subItem, item.props.id)
      );
    }
  } else {
    if (item.id) {
      item.id = joinNonEmptyStrings(parentId, item.id);
    }
  }

  return item;
};

const ProductCategorySiteSelector = props => {
  const variables = {
    siteId: props.siteId
  };

  if (PRODUCT_SELECTOR_TYPE_SEARCH_RESULT === props.selectorType) {
    variables.searchKey = props.searchKey;
    variables.filterBy = [];
    variables.searchOptions = getSearchOptions();
  } else if (PRODUCT_SELECTOR_TYPE_FAVORITE === props.selectorType) {
    variables.filterBy = props.graphqlClient.filterInput(
      "id",
      props.favoriteProducts.map(id => +id)
    );
  } else {
    variables.searchId = props.searchId;
    variables.searchKey = props.match.params.categoryId;
    variables.selectorType = props.graphqlClient.asEnum(props.selectorType);

    if (!variables.searchId && Number.isInteger(+variables.searchKey)) {
      variables.searchId = +variables.searchKey;
      variables.searchKey = null;
    }
  }

  const gqlProps = {
    graphqlClient: props.graphqlClient,
    query: [
      PRODUCT_SELECTOR_TYPE_SEARCH_RESULT,
      PRODUCT_SELECTOR_TYPE_FAVORITE
    ].includes(props.selectorType)
      ? gqlProductsFilterSetup
      : gqlProductFilterSetup,
    variables,
    dataTransformer: data => {
      const placeholderItems = [
        {
          as: "range",
          props: {
            __typename: "RangeFilterProps",
            id: "price",
            collapsible: true,
            collapsed: false,
            title: "PRECIO",
            wrap: true,
            unit: "EUR",
            min: 0,
            max: 100,
            defaultValue: null,
            showRangeLabel: true,
            readOnly: false,
            scale: 0,
            step: 1,
            selected: null,
            detail: {
              value: 0,
              items: []
            }
          }
        }
      ];

      const key = [
        PRODUCT_SELECTOR_TYPE_SEARCH_RESULT,
        PRODUCT_SELECTOR_TYPE_FAVORITE
      ].includes(props.selectorType)
        ? "productsFilterSetup"
        : "productFilterSetup";

      const items = (data[key].items || placeholderItems)
        .filter(propIsSelected)
        .map(item => filterPropsTransformer(item, props))
        //.map(item => nestedIdTransformer(item, null)); :( WRONG, will pass index as parentId argument
        .map(item => nestedIdTransformer(item)); // :) GOOD

      const itemsPerRow = data[key].itemsPerRow || undefined;

      return {
        searchKey: [PRODUCT_SELECTOR_TYPE_SEARCH_RESULT].includes(
          props.selectorType
        )
          ? variables.searchKey
          : undefined,
        items,
        itemsPerRow,
        selectorType: props.selectorType,
        autoFilter: AUTO_FILTERING,
        searchOptions: props.searchOptions
      };
    },
    wraps: ProductCategorySelector,
    lazyLoader: { style: { minHeight: "50vh" } }
  };

  return <GraphQLComponent {...gqlProps} />;
};

ProductCategorySiteSelector.mapStateToProps = (state, ownProps) => {
  const items = state.productFavoriteResult.items;

  return {
    favoriteProducts: Object.keys(items).filter(productId => items[productId])
  };
};
ProductCategorySiteSelector.mapDispatchToProps = {};

export default connectHOCs(ProductCategorySiteSelector, { withAll: true });
