import Picture from "@components-core/Picture";
import PureComponent from "@components-core/PureComponent";
import { connectHOCs } from "@components-utils";
import {
  addCartChangeSubscriber,
  removeCartChangeSubscriber
} from "@redux-actions/cart";
import { ShoppingCartAssistantBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { CART_ADD_PRODUCT } from "../../redux/actionTypes";

class ShoppingCartAssistant extends PureComponent {
  constructor(props) {
    super(props);

    this.myRef = React.createRef();

    this.state = {
      show: 0,
      cartItem: null,
      top: null
    };

    // the observer of page-header mutations
    this.observer = null;

    this.timeout = null;

    this.onCartAddProduct = this.onCartAddProduct.bind(this);
    this.handleRedirectCheckout = this.handleRedirectCheckout.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.clearTimeout = this.clearTimeout.bind(this);
  }

  handleClose(e) {
    this.setState({ show: false });

    this.clearTimeout();
  }

  clearTimeout() {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
      this.timeout = null;
    }
  }

  handleRedirectCheckout(e) {
    const url = this.props.pathfinder.get("checkout");

    this.setState({ show: false });

    this.props.history.push(url);
  }

  onCartAddProduct({ type, product, preorder, quantity, updated }) {
    if (type !== CART_ADD_PRODUCT) {
      return;
    }

    // autohide the assistant after `timeout` seconds
    const callback = this.props.timeout
      ? () => {
          this.clearTimeout();

          this.timeout = setTimeout(
            () => this.setState({ show: false }),
            this.props.timeout * 1000
          );
        }
      : null;

    this.setState(
      { cartItem: { product, preorder, quantity }, show: true },
      callback
    );
  }

  componentDidMount() {
    // subscribe to cart-add product event
    this.props.addCartChangeSubscriber(this.onCartAddProduct);

    this.observer = this.observePageHeaderContainer(() => {
      const rect = this.myRef.current.previousSibling.getBoundingClientRect();
      this.setState({ top: rect.height });
    });
  }

  componentWillUnmount() {
    this.clearTimeout();

    // unsubscribe from cart-add product event
    this.props.removeCartChangeSubscriber(this.onCartAddProduct);

    this.observer.disconnect();
  }

  /**
   * @description Monitor page-header mutations
   * @param {Function} callback A callback function called on a new mutation
   * @returns {MutationObserver}
   * @memberof ShoppingCartAssistant
   */
  observePageHeaderContainer(callback) {
    const config = { childList: true, subtree: true };

    const cb = function (mutationsList, observer) {
      if (
        Array.from(mutationsList).some(
          mutation => "childList" === mutation.type
        )
      )
        if ("function" === typeof callback) {
          callback();
        }
    };

    const observer = new MutationObserver(cb);
    observer.observe(this.myRef.current.previousSibling, config);

    return observer;
  }

  render() {
    const show = this.state.show ? null : "d-none";
    const _i18n = this.props.i18n.components.ShoppingCart.ASSISTANT;

    let content = null;

    if (this.state.cartItem) {
      const title = this.state.cartItem.product.title;

      const img = {
        ...this.state.cartItem.product.img,
        title: this.state.cartItem.product.title
      };

      const topFeatures = this.state.cartItem.product.topFeatures.join("|");

      content = (
        <Container>
          <Row>
            <Col
              md="5"
              className={getComponentClassName(
                ShoppingCartAssistantBS,
                "thumbnail"
              )}
            >
              <Picture
                {...img}
                imgSize={{ maxWidth: "142px", maxHeight: "142px" }}
              />
            </Col>
            <Col md="7" className="pl-0">
              <Container>
                <Row className="text-success h6">
                  <Col>{_i18n.feedback}</Col>{" "}
                </Row>
                <Row className="h6">
                  <Col>{title}</Col>
                </Row>
                <Row className="small">
                  <Col>{topFeatures}</Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Button
                variant="success"
                className="w-100"
                onClick={this.handleRedirectCheckout}
              >
                {_i18n.checkout}
              </Button>
            </Col>
            <Col md="6">
              <Button
                variant="secondary"
                className="w-100"
                onClick={this.handleClose}
              >
                {_i18n.shopping}
              </Button>
            </Col>
          </Row>
        </Container>
      );
    }

    return (
      <div
        style={{ top: this.state.top }}
        className={getComponentClassName(ShoppingCartAssistantBS, null, show)}
        ref={this.myRef}
      >
        {content}
      </div>
    );
  }
}

ShoppingCartAssistant.propTypes = {
  float: PropTypes.oneOf(["left", "right"]),
  timeout: PropTypes.number
};

ShoppingCartAssistant.defaultProps = {
  float: "right",
  addCartChangeSubscriber
};

ShoppingCartAssistant.mapStateToProps = null;
ShoppingCartAssistant.mapDispatchToProps = {
  addCartChangeSubscriber,
  removeCartChangeSubscriber
};

export default connectHOCs(ShoppingCartAssistant, {
  withSite: true,
  withConnect: true,
  withRouter: true
});
