import PropTypes from "prop-types";
import JSXProps from "./JSXProps";

export default function CollapsibleContainerProps() {
  return {
    id: PropTypes.string.isRequired,
    title: JSXProps(),
    wrapper: PropTypes.any,
    collapsible: PropTypes.bool,
    collapsed: PropTypes.bool,
    onChange: PropTypes.func,
    collapsedClassName: PropTypes.string,
    foldedClassName: PropTypes.string
  };
}
