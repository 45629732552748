import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import {
  Card,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Nav,
  Row
} from "react-bootstrap";
import MediaQuery from "react-responsive";
import { FooterLinksBS } from "@style-variables";
import { mediaBreakpoint } from "@utils/breakpoints";
import { getComponentClassName } from "@utils/strings";
import Picture from "@components-core/Picture";
import PureComponent from "@components-core/PureComponent";
import RouteLink from "@components-core/RouteLink";
import LinkItem from "../../Link/Item";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";

export default class PageFooterLinks extends PureComponent {
  renderIcons(item, j) {
    const colspan = Math.floor(12 / item.items.length);

    const items = item.items.map((elem, i) => {
      if (!!elem.img && elem.img) {
        let imgProps = { title: elem.title };

        if (typeof elem.img === "string") {
          imgProps.src = elem.img;
        }
        if (typeof elem.img === "object") {
          imgProps = { ...imgProps, ...elem.img };
        }

        return (
          <Col
            key={i}
            md={
              colspan +
              (item.items.length - 1 === i
                ? 12 - item.items.length * colspan
                : 0)
            }
          >
            <RouteLink to={elem.url} title={elem.title}>
              <Nav.Link as="div" href={elem.url}>
                <Picture {...imgProps} />
              </Nav.Link>
            </RouteLink>
          </Col>
        );
      }
      return (
        <Col md={colspan} key={i}>
          <RouteLink to={elem.url} title={elem.title}>
            <Nav.Link as="div" href={elem.url}>
              <FontAwesomeIcon size="2x" icon={elem.icon} title={elem.title} />
            </Nav.Link>
          </RouteLink>
        </Col>
      );
    });

    return (
      <Container
        key={j}
        className={getComponentClassName(FooterLinksBS, "icons", item.class)}
      >
        <Row className="m-auto text-center">{items}</Row>
      </Container>
    );
  }

  renderLinkItems(items) {
    return items.map((item, j) => {
      if (!item.items) {
        const title = item.title
          ? item.title
          : item.caption
          ? item.caption
          : null;

        return (
          <LinkItem
            {...item}
            title={title}
            key={j}
            asListGroupItem
            className={getComponentClassName(FooterLinksBS, "item", item.class)}
          />
        );
      }

      return this.renderIcons(item, j);
    });
  }

  renderItemGroup(key) {
    const item = this.props.items[key];

    return (
      <ListGroup variant="flush" key={key}>
        <ListGroupItem
          className={getComponentClassName(
            FooterLinksBS,
            "title",
            "text-uppercase"
          )}
        >
          <h2>{item.title}</h2>
        </ListGroupItem>
        {this.renderLinkItems(item.items)}
      </ListGroup>
    );
  }

  renderItem(key, indecs) {
    const keys = [...(indecs || [])];

    if (!keys.length) {
      keys.push(key);
    }

    const item = this.props.items[key];

    return (
      <Col
        md="3"
        xs={item.xs}
        key={key}
        className={getComponentClassName(FooterLinksBS, "column")}
      >
        {keys.map(i => this.renderItemGroup(i))}
      </Col>
    );
  }

  renderMobileDevice(key) {
    const items = [];

    items.push(this.renderItem(0, [0, 2]));
    items.push(this.renderItem(1));

    return (
      <MediaQuery key={key} {...mediaBreakpoint.mobile}>
        {items}
      </MediaQuery>
    );
  }

  renderDesktopDevice(key) {
    const items = this.props.items.map((item, i) => this.renderItem(i));

    return (
      <MediaQuery key={key} {...mediaBreakpoint.default}>
        {items}
      </MediaQuery>
    );
  }

  renderItems() {
    return [this.renderMobileDevice(0), this.renderDesktopDevice(1)];
  }

  render() {
    const items = this.renderItems();

    return (
      <Row
        className={getComponentClassName(
          FooterLinksBS,
          null,
          "justify-content-md-center"
        )}
      >
        {items}
        <Col
          md="3"
          xs={this.props.description.xs}
          className={getComponentClassName(FooterLinksBS, "description")}
        >
          <Card style={{ border: "none" }}>
            <Card.Body>
              <Card.Title
                className={getComponentClassName(FooterLinksBS, "title")}
              >
                <h2>{this.props.description.title}</h2>
              </Card.Title>
              <Card.Text>{this.props.description.text}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }
}

PageFooterLinks.propTypes = {
  ...ItemsAwareProps(
    true,
    "items",
    PropTypes.shape({
      title: PropTypes.string,
      xs: PropTypes.number,
      ...ItemsAwareProps
    })
  ),
  description: PropTypes.shape({
    title: PropTypes.string,
    xs: PropTypes.number,
    ...PropTypes.objectOf(PropTypes.string).isRequired
  })
};
