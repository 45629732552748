import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import ProductSpecsProps from "@prop-types/ProductSpecsProps";
import { GroupedTableBS } from "@style-variables";
import { getComponentClassName, joinNonEmptyStrings } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { OverlayTrigger, Popover, Table } from "react-bootstrap";
import { withPlaceholder } from "./Placeholder";

export default class GroupedTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showMore: false };

    this.popovers = {};
  }

  hasLimit() {
    const result =
      !this.state.showMore && !!this.props.limit && !!this.props.limit.value;

    return result;
  }

  getPopoverOverlay(groupKey, specKey) {
    const mixKey = groupKey + "." + specKey;

    if (!(mixKey in this.popovers)) {
      this.popovers[mixKey] = (
        <Popover placement="auto">
          {this.props.items[groupKey].items[specKey].description}
        </Popover>
      );
    }
    return this.popovers[mixKey];
  }

  renderRows() {
    let c = 0;

    return this.props.items.map((item, groupKey, arr1) => {
      let showMore = null;
      let specClass = "";

      c++;
      const group_items = item.items.map((spec, specKey) => {
        c++;
        const icon = spec.description ? (
          <OverlayTrigger
            trigger="click"
            placement="auto"
            overlay={this.getPopoverOverlay(groupKey, specKey)}
          >
            <FontAwesomeIcon
              icon="info-circle"
              size="1x"
              className={getComponentClassName(
                GroupedTableBS,
                "icon",
                "cursor-pointer mx-1"
              )}
            />
          </OverlayTrigger>
        ) : null;

        specClass =
          this.hasLimit() && c > this.props.limit.value ? "d-none " : "";

        const value =
          spec.boolean !== null ? (
            <FontAwesomeIcon
              color={spec.boolean ? "green" : "red"}
              icon={spec.boolean ? "check-circle" : "times-circle"}
            />
          ) : "" === spec.value ? (
            <FontAwesomeIcon className="text-muted" icon="question-circle" />
          ) : (
            withPlaceholder(this.props.placeholder, spec.value)
          );

        return (
          <tr
            key={specKey}
            className={getComponentClassName(
              GroupedTableBS,
              "item",
              joinNonEmptyStrings(specClass, spec.className, " ")
            )}
          >
            <td>
              {withPlaceholder(this.props.placeholder, spec.title)}
              {icon}
            </td>
            <td>{value}</td>
          </tr>
        );
      });

      if (groupKey === arr1.length - 1) {
        showMore =
          this.hasLimit() && c > this.props.limit.value ? (
            <tr
              className={getComponentClassName(
                GroupedTableBS,
                "showMore",
                "bg-secondary text-white cursor-pointer"
              )}
              onClick={() => this.setState({ showMore: !this.state.showMore })}
            >
              <td colSpan="2">
                {this.props.limit.title}
                <FontAwesomeIcon className="mx-1" icon="angle-right" />
              </td>
            </tr>
          ) : null;
      }

      return (
        <React.Fragment key={groupKey}>
          <tr>
            <td
              colSpan="2"
              className={getComponentClassName(
                GroupedTableBS,
                "group",
                joinNonEmptyStrings(specClass, item.className, " ")
              )}
            >
              <h3>{withPlaceholder(this.props.placeholder, item.group)}</h3>
            </td>
          </tr>
          {group_items}
          {showMore}
        </React.Fragment>
      );
    });
  }

  render() {
    return (
      <Table
        striped
        hover
        size="sm"
        responsive
        className={getComponentClassName(
          GroupedTableBS,
          null,
          this.props.className
        )}
      >
        <tbody>{this.renderRows()}</tbody>
      </Table>
    );
  }
}

GroupedTable.propTypes = {
  ...ItemsAwareProps(false, null, PropTypes.shape(ProductSpecsProps)),
  className: PropTypes.string,
  limit: PropTypes.shape({
    value: PropTypes.number.isRequired,
    title: PropTypes.string
  }),
  placeholder: PropTypes.bool
};
