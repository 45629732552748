import React from "react";
import { Card, Form } from "react-bootstrap";
import { TextareaCardBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import TextareaCardProps from "@prop-types/TextareaCardProps";

const TextareaCard = ({
  id,
  label,
  value,
  defaultValue,
  onChange,
  onBlur,
  disabled,
  readOnly
}) => {
  return (
    <Card className={getComponentClassName(TextareaCardBS)}>
      <Card.Body>
        <Form.Group controlId={id}>
          <Form.Label>{label}</Form.Label>
          <Form.Control
            as="textarea"
            rows="4"
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
            readOnly={readOnly}
          />
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

export default TextareaCard;

TextareaCard.propTypes = TextareaCardProps;
