import { connectHOCs } from "@components-utils";
import {
  findPathBySelectorType,
  PRODUCT_SELECTOR_TYPE_BRAND
} from "@constants";
import GraphQLComponent from "@graphql-component";
import { SORT_ASC } from "@graphql-operators";
import gqlProductCategorySummaryFragment from "@graphql-query/productCategorySummaryFragment.gql";
import gqlProductImageFieldsFragment from "@graphql-query/productImageFieldsFragment.gql";
import gqlProductImageFragment from "@graphql-query/productImageFragment.gql";
import gqlSEOScoreFragment from "@graphql-query/seoScoreFragment.gql";
import gqlTrademarks from "@graphql-query/trademarks.gql";
import React from "react";
import LogoList from "./LogoList";

const BrandsLogoList = props => {
  const gqlProps = {
    graphqlClient: props.graphqlClient,
    query: [
      gqlTrademarks,
      gqlProductImageFragment,
      gqlProductCategorySummaryFragment,
      gqlProductImageFieldsFragment,
      gqlSEOScoreFragment
    ],
    variables: {
      siteId: props.siteId,
      orderBy: props.graphqlClient.sortOrderInput("title", SORT_ASC)
    },
    dataTransformer: data => ({
      items: (data.trademarks || [])
        .filter(trademark => Boolean(trademark.text))
        .map(tradermark => ({
          ...tradermark,
          url: tradermark.url.startsWith("http")
            ? tradermark.url
            : findPathBySelectorType(
                props.pathfinder,
                PRODUCT_SELECTOR_TYPE_BRAND,
                tradermark.url
              )
        }))
        .map(trademark => props.imgBuilder(trademark))
    }),
    wraps: LogoList
  };

  return <GraphQLComponent {...gqlProps} />;
};

BrandsLogoList.defaultProps = {};

BrandsLogoList.mapValueToProps = value => {
  return {
    imgBuilder: trademark => {
      return {
        id: trademark.id,
        img: {
          src: value.imgResolver(value.imgDir, trademark.imgSrc),
          cloudinary: value.cloudinary,
          sizes: { any: 176 },
          aspect: 0.17
        },
        url: trademark.url,
        title: trademark.title,
        text: trademark.text,
        summary: trademark.summary
      };
    }
  };
};

export default connectHOCs(BrandsLogoList, {
  withSite: true,
  withGraphQL: true
});
