import { ActiveFilterListBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";

function ActiveResultSet(props) {
  return (
    <label
      className={getComponentClassName(
        ActiveFilterListBS,
        "label",
        [
          props.className,
          "text-center text-md-left",
          props.count ? null : "text-danger"
        ]
          .filter(Boolean)
          .join(" ")
      )}
      data-result-count={props.count}
    >
      {props.title.replace("%count%", props.count)}
    </label>
  );
}

ActiveResultSet.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  count: PropTypes.number.isRequired
};

export default ActiveResultSet;
