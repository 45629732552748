import InfiniteScrollComponent from "@components-core/InfiniteScrollComponent";
import { connectHOCs } from "@components-utils";
import { PRODUCT_SELECTOR_TYPE_SERIES } from "@constants";
import { ProductSerieListBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import SiteProductSlider from "../Product/SiteRouteSlider";
import ProductSerieItem from "./Item";

class ProductSerieList extends InfiniteScrollComponent {
  renderItems() {
    let items = this.state.items;

    if (!items.length) {
      if (this.props.placeholder) {
        items = [
          {
            categorySearchKey: "0",
            aspect: 0.537,
            img: {
              sizes: {
                mobilePortrait: 328,
                mobileLandscape: 508,
                tabletPortrait: 688,
                tabletLandscape: 619,
                desktop: 759
              }
            }
          }
        ];
      }
    }

    return items.map((item, index) => {
      const horizontal = this.props.horizontal;

      let serieItems = null;

      if (horizontal && item.categorySearchKey) {
        serieItems = (
          <SiteProductSlider
            selectorType={PRODUCT_SELECTOR_TYPE_SERIES}
            searchKey={item.categorySearchKey}
          />
        );
      }

      return (
        <Row key={index} className="pb-5">
          <Col className="px-0">
            <ProductSerieItem
              horizontal={horizontal}
              truncateText={this.props.truncateText}
              showAllProductsButton={this.props.showAllProductsButton}
              {...item}
              titleAs="h3"
              placeholder={this.props.placeholder}
            />
            {serieItems}
          </Col>
        </Row>
      );
    });
  }

  render() {
    const more = this.renderMoreItemsButton();

    const items = this.renderItems();

    return (
      <Container
        className={getComponentClassName(this.props.className)}
        data-series-items-count={this.state.items.length}
      >
        {items}
        {more}
      </Container>
    );
  }
}

ProductSerieList.propTypes = {
  ...InfiniteScrollComponent.propTypes,
  horizontal: PropTypes.bool,
  truncateText: PropTypes.bool,
  showAllProductsButton: PropTypes.bool,
  placeholder: PropTypes.bool
};

ProductSerieList.defaultProps = {
  ...InfiniteScrollComponent.defaultProps,
  horizontal: false,
  truncateText: true,
  showAllProductsButton: true,
  className: ProductSerieListBS
};

export default connectHOCs(ProductSerieList, { withSite: true });
