import GenericCarousel from "@components-core/GenericCarousel";
import { PRODUCT_PAGE_SELECTORS } from "@constants";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import MaxSizeProps from "@prop-types/MaxSizeProps";
import ProductProps from "@prop-types/ProductProps";
import { ProductSliderBS } from "@style-variables";
import { mediaBreakpoint } from "@utils/breakpoints";
import PreloadHelper from "@utils/preload";
import { escapeReact } from "@utils/react";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import MediaQuery from "react-responsive";
import { imgSVG } from "../../sites/_default-img";
import ProductCategoryItem from "../ProductCategory/Item";

const helper = new PreloadHelper();

function ProductSlider(props) {
  let items = props.items;

  if (!items.length) {
    if (props.placeholder) {
      items = Array.from({ length: props.itemsPerSlide }, () => ({}));
    } else {
      return null;
    }
  }

  const _props = {
    ...props,
    items: items.map(item => ({ img: item.img, title: item.title })),
    preloadCount: 0,
    prefetchCount: props.itemsPerSlide
  };

  helper.setProps(_props);
  helper.mountLinks();

  const categoryItems = items.map((item, index) => {
    return (
      <ProductCategoryItem
        key={index}
        titleAs="h3"
        item={item}
        impressionList={props.impressionList}
        selectorType={props.selectorType}
        imgSize={props.imgSize ? props.imgSize : null}
        placeholder={props.placeholder}
        comparable={
          "undefined" === typeof item.comparable ? undefined : item.comparable
        }
      />
    );
  });

  const _imgSVG = imgSVG();
  const sliderProps = {
    indicators: false,
    controls: true,
    ...props,
    items: categoryItems,
    colSpan: { sm: 12, md: 6, lg: 4, xl: 3 },
    className: getComponentClassName(ProductSliderBS, "slider"),
    prevIcon: _imgSVG.carouselArrowLeft,
    nextIcon: _imgSVG.carouselArrowRight
  };

  const carouselBreakpoints = {
    xlUp: 0,
    lgOnly: -1,
    tablet: -2,
    mobile: 1 - props.itemsPerSlide
  };

  const responsiveCarousel = Object.keys(carouselBreakpoints).map(
    (breakpoint, i) => (
      <MediaQuery key={i} {...mediaBreakpoint[breakpoint]}>
        <GenericCarousel
          {...{
            ...sliderProps,
            itemsPerSlide: props.itemsPerSlide + carouselBreakpoints[breakpoint]
          }}
        />
      </MediaQuery>
    )
  );

  const TitleTag = props.titleAs || "h2";

  const title =
    props.title || props.placeholder ? (
      <Row>
        <Col>
          <TitleTag
            className={getComponentClassName(ProductSliderBS, "title", "my-4")}
          >
            {props.title}
          </TitleTag>
        </Col>
      </Row>
    ) : null;

  const text = props.text ? (
    <Row>
      <Col>{escapeReact(props.text, props.pathfinder)}</Col>
    </Row>
  ) : null;

  return (
    <Container
      className={getComponentClassName(ProductSliderBS, null, props.className)}
    >
      {title}
      {text}
      <Row className={getComponentClassName(ProductSliderBS, "items")}>
        <Col>{responsiveCarousel}</Col>
      </Row>
    </Container>
  );
}

ProductSlider.propTypes = {
  title: PropTypes.string,
  titleAs: PropTypes.string,
  text: PropTypes.string,
  ...ItemsAwareProps(false, null, PropTypes.shape(ProductProps())),
  itemsPerSlide: PropTypes.number,
  className: PropTypes.string,
  onClick: PropTypes.func,
  imgSize: PropTypes.shape(MaxSizeProps),
  impressionList: PropTypes.string,
  selectorType: PropTypes.oneOf(PRODUCT_PAGE_SELECTORS),
  prefetch: PropTypes.bool,
  preload: PropTypes.bool,
  placeholder: PropTypes.bool
};

ProductSlider.defaultProps = {
  prefetch: true,
  preload: false
};

export default React.memo(ProductSlider);
