import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ContactCardDeckBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import ContactAddressProps from "@prop-types/ContactAddressProps";
import ContactCallProps from "@prop-types/ContactCallProps";
import ContactEmailProps from "@prop-types/ContactEmailProps";
import ContactWorktimeProps from "@prop-types/ContactWorktimeProps";
import ContactAddress from "./Address";
//import ContactCall from "./Call";
import ContactEmail from "./Email";
import ContactWorktime from "./Worktime";

const ContactCardDeck = ({ worktime, email, address }) => {
  //<ContactCall {...props.call} />
  return (
    <Container className={getComponentClassName(ContactCardDeckBS)}>
      <Row>
        <Col lg="4" sm="12">
          <ContactWorktime {...worktime} />
        </Col>
        <Col lg="4" sm="12">
          <ContactEmail {...email} />
        </Col>
        <Col lg="4" sm="12">
          <ContactAddress {...address} />
        </Col>
      </Row>
    </Container>
  );
};

export default ContactCardDeck;

ContactCardDeck.propTypes = {
  worktime: PropTypes.shape(ContactWorktimeProps),
  address: PropTypes.shape(ContactAddressProps),
  email: PropTypes.shape(ContactEmailProps),
  call: PropTypes.shape(ContactCallProps)
};
