import PropTypes from "prop-types";
import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import { MenuBarBS, MenuBarItemBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import MenuBarItem from "./Item";

const MenuBar = props => {
  //const md = props.items ? Math.ceil(12 / props.items.length) : 12;

  //const [dropdownToggled, setDropdownToggled] = useState(false);

  const onMenuBarItemHover = hover => e => {
    const anchor = e.currentTarget.querySelector("a");

    if (anchor) {
      anchor.click();
    } else {
      e.currentTarget.click();
    }
  };

  const className = getComponentClassName(MenuBarItemBS, null, `col col-xs-12`);

  const autoExpandProps = {};

  if (props.autoExpand) {
    // autoExpandProps.onDropdownToggled = (e, toggled) => {
    //   setDropdownToggled(toggled);
    // };

    autoExpandProps.onDropdownMouseEnter = onMenuBarItemHover(true);
    autoExpandProps.onDropdownMouseLeave = onMenuBarItemHover(false);
  }

  const items = props.items.map((item, index) => (
    <MenuBarItem
      key={index}
      {...{ ...item, ...autoExpandProps }}
      className={className}
      autoExpand={props.autoExpand}
    />
  ));

  return (
    <header className={getComponentClassName(MenuBarBS)}>
      <Navbar collapseOnSelect expand="md" bg="dark" variant="dark">
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">{items}</Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

export default MenuBar;

MenuBar.propTypes = {
  ...ItemsAwareProps,
  autoExpand: PropTypes.bool
};

MenuBar.defaultProps = {
  autoExpand: false
};
