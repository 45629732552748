import deepmerge from "deepmerge";
import { isAdminConfig } from "../utils/functions";
import { getDomainById, getGraphqlDomain, getPaymentDomain } from "./domains";

const isDevelopment = () =>
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

const isTest = () => !process.env.NODE_ENV || process.env.NODE_ENV === "test";

const proto =
  "undefined" === typeof window ? "https:" : window.location.protocol;

// the port GraphQL runs
//const port = ":"+(proto === "https:" ? 443 : "80");
const port = "";

const isDevEnvironment = key => {
  const _key =
    key ||
    ("undefined" === typeof window ? process.env.SITE_ID : window.__SITE_ID__);

  // on local dev system we are using fake test domains/subdomains
  const devDomains = getDomainById(_key, "dev");

  if (!(_key && devDomains.length)) {
    return false;
  }

  const result =
    "undefined" === typeof window // probably it's a NodeJS instance
      ? isDevelopment()
      : devDomains
          .concat("localhost", "127.0.0.1", "0.0.0.0")
          .indexOf(window.location.hostname) !== -1;

  return result;
};

const isTestEnvironment = key => {
  const _key =
    key ||
    ("undefined" === typeof window ? process.env.SITE_ID : window.__SITE_ID__);

  // on local dev system we are using fake test domains/subdomains
  const testDomains = getDomainById(_key, "test");

  if (!(_key && testDomains.length)) {
    return false;
  }

  const result =
    "undefined" === typeof window // probably it's a NodeJS instance
      ? isTest()
      : testDomains.indexOf(window.location.hostname) !== -1;

  return result;
};

const getGraphqlEndpoint = (siteId, env) => {
  const _siteId =
    siteId ||
    ("undefined" === typeof window ? process.env.SITE_ID : window.__SITE_ID__);

  const _env =
    "test" === env || ("undefined" === typeof env && isTestEnvironment(_siteId))
      ? "test"
      : "development" === env ||
        ("undefined" === typeof env && isDevEnvironment(_siteId))
      ? "dev"
      : "prod";

  let graphQlOrigin =
    proto + "//" + getGraphqlDomain(_env) + port + "/site-" + _siteId;

  /*if (_env === "prod" && _siteId == "69") {
    graphQlOrigin =
      proto + "//" + "graphql.lsbolagen.net" + port + "/site-" + _siteId;
  }*/

  return graphQlOrigin + "/graphql" + (isAdminConfig() ? "?admin=1" : "");
};

const mergedGraphql = (siteId, graphql, env) => {
  const graphQlEndpoint = getGraphqlEndpoint(siteId, env);

  return deepmerge(
    {
      endpoint: graphQlEndpoint,
      batch: {
        enabled: true,
        interval: 10, // batch all request within an `interval` milliseconds window
        limit: 10 // the max number of queries to batch, the difference will be scheduled separately
      },
      cache: {
        enabled: true,
        stats: true, // only under development
        interval: 1000, // only under development
        limit: 10 * 1024 * 1024 // max 10MB, 0 = unlimited
      }
    },
    graphql || {}
  );
};

const getPaymentEndpoint = siteId => {
  const paymentOrigin =
    proto +
    "//" +
    getPaymentDomain(
      isTestEnvironment(siteId)
        ? "test"
        : isDevEnvironment(siteId)
        ? "dev"
        : "prod"
    ) +
    port +
    "/site-" +
    siteId;

  return paymentOrigin + "/payment";
};

export { getGraphqlEndpoint, getPaymentEndpoint, mergedGraphql };
