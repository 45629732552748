import { connectHOCs } from "@components-utils";
import ProductPriceProps from "@prop-types/ProductPriceProps";
import TitleTextProps from "@prop-types/TitleTextProps";
import { CheckoutSubtotalBS } from "@style-variables";
import {
  formatCurrency,
  getComponentClassName,
  joinNonEmptyStrings
} from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Card } from "react-bootstrap";

const CheckoutSubtotal = ({
  isCartEmpty,
  className,
  title,
  amount,
  currencyPrefix,
  currencySuffix,
  i18n,
  vat
}) => {
  if (isCartEmpty) {
    return null;
  }

  return (
    <Card
      className={getComponentClassName(
        CheckoutSubtotalBS,
        null,
        joinNonEmptyStrings(className, "text-center text-md-right", " ")
      )}
    >
      <Card.Body>
        <div className="font-weight-bold h4">
          {title + " " + formatCurrency(amount, currencyPrefix, currencySuffix)}
        </div>
        <div>
          {i18n.pages.CheckoutShoppingCart.ITEMS_VAT +
            " " +
            formatCurrency(vat, currencyPrefix, currencySuffix)}
        </div>
      </Card.Body>
    </Card>
  );
};

CheckoutSubtotal.propTypes = {
  ...TitleTextProps,
  ...ProductPriceProps,
  className: PropTypes.string
};

CheckoutSubtotal.mapStateToProps = (state, ownProps) => {
  const gross = state.calculatorResult.gross || {};
  const vat = state.calculatorResult.vat || {};

  return {
    isCartEmpty: !state.cart.items.length,
    amount: gross.orderValue || 0,
    vat: vat.orderValue || 0,
    currencyCode: state.calculatorResult.currencyCode,
    currencyPrefix: state.calculatorResult.currencyPrefix,
    currencySuffix: state.calculatorResult.currencySuffix
  };
};

CheckoutSubtotal.mapDispatchToProps = null;

CheckoutSubtotal.mapValueToProps = value => ({
  ...value.checkout.subtotal
});

export default connectHOCs(CheckoutSubtotal, {
  withSite: true,
  withConnect: true
});
