import { actualDeviceBreakpoint } from "./breakpoints";
import { getCloudinaryUrl } from "./cloudinary";
import { debug } from "./debug";
import { getContentType } from "./http";

// common breakpoints image max-width by itemsPerSlide
const breakpointsImgSizeByItemsPerSlide = {
  mobilePortrait: { default: 323, 2: 155 }, // tested on iPhone 6-14
  mobileLandscape: { default: 473, 2: 218, 3: 133 }, // tested on iPhone 6-14
  tabletPortrait: { default: 185, 1: 623, 2: 293, 4: 128 }, // tested on iPad
  tabletLandscape: { default: 333, 1: 623, 2: 293, 4: 128 }, // tested on iPad and iPhone 11+
  desktop: { default: 238, 1: 473, 2: 376, 4: 229, 6: 101 }
};

// common breakpoints optimal itemsPerSlide
const breakpointItemsPerSlide = itemsPerSlide => ({
  mobilePortrait: 1,
  mobileLandscape: 1,
  tabletPortrait: itemsPerSlide,
  tabletLandscape: itemsPerSlide,
  desktop: itemsPerSlide
});

/**
 * @description Get the image width that matches best the given itemsPerSlide
 * @param {number} itemsPerSlide The number of visible items
 * @returns {number} Returns the image width
 */
const imgWidthByItemsPerSlide = itemsPerSlide => {
  const breakpoint = actualDeviceBreakpoint();
  return (
    breakpointsImgSizeByItemsPerSlide[breakpoint][itemsPerSlide] ||
    breakpointsImgSizeByItemsPerSlide[breakpoint].default
  );
};

/**
 * @description Get the slider col size for the given itemsPerSlide
 * @param {number} colspan The item colspan
 * @returns {Object} Returns the image width for each column size
 */
const getSliderImgSize = colspan => {
  const itemsPerSlide = Math.ceil(12 / colspan);

  const obj = breakpointItemsPerSlide(itemsPerSlide);

  return Object.keys(obj).reduce(
    (carry, breakpoint) =>
      Object.assign(carry, {
        [breakpoint]: imgWidthByItemsPerSlide(obj[breakpoint])
      }),
    {}
  );
};

// supported video extensions
const SUPPORTED_VIDEO_EXT = [
  "mp4",
  "webm",
  "gif",
  "mpeg",
  "mpg",
  "ogv",
  "avi",
  "mkv",
  "flv",
  "wmv",
  "mov",
  "3gp",
  "m4v",
  "vob"
];

// supported image extensions
// https://cloudinary.com/documentation/image_transformations#image_format_support
const SUPPORTED_IMAGE_EXT = [
  "jpg",
  "jpeg",
  "jfif",
  "png",
  "gif",
  "tif",
  "tiff",
  "svg",
  "webp"
];

const getImageMimeType = path => {
  if (/\.png$/.test(path)) {
    return "image/png";
  } else if (/\.(jpg|jpeg)$/.test(path)) {
    return "image/jpeg";
  } else if (/\.svg$/.test(path)) {
    return "image/svg+xml";
  } else if (/\.gif$/.test(path)) {
    return "image/gif";
  } else if (/\.webp$/.test(path)) {
    return "image/webp";
  }

  throw Error(`Unexpected image filename ${path}`);
};

const getMimeFileExt = mime => {
  switch (mime) {
    case "image/png":
      return ".png";
    case "image/jpeg":
      return ".jpg,.jpeg";
    case "image/svg+xml":
      return ".svg";
    case "image/gif":
      return ".gif";
    case "image/webp":
      return ".webp";
    default:
      throw Error(`Unexpected image mime type ${mime}`);
  }
};

/**
 * @description Get the animated image MIME types
 * @returns {Array}
 */
const getAnimatedImageMimeTypes = () => ["image/apng", "image/gif"];

/**
 * @description Checks whether the given image is animted by sending a HEAD request then inspecting the response content-type
 * @param {String|Object} source One of: the image URI (when cloudinary=null), the Cloudinary public Id (cloudinary given), an object with prototype {src: String, cloudinary: Object} (cloudinary=null)
 * @param {Object} [cloudinary=null] Optionally the Cloudinary configuration
 * @returns {Promise} Returns a promise that resolves to TRUE when the image is animated, FALSE otherwhise
 */
const isAnimatedImage = (source, cloudinary = null) => {
  // source: http://
  let url;

  if (null === cloudinary) {
    // source: {src:{..}, cloudinary:{..}}
    if ("object" === typeof source) {
      url = getCloudinaryUrl(source);
    } // source: http://...
    else {
      url = source;
    }
  }
  // source: "...", cloudinary:{..}
  else if ("object" === typeof cloudinary) {
    url = getCloudinaryUrl({ src: source, cloudinary });
  } else {
    return Promise.reject(
      new Error(
        `isAnimatedImage (${typeof source}, ${
          cloudinary ? typeof cloudinary : "NULL"
        }) : unexepected argument types`
      )
    );
  }

  // drop the Cloudinary image URL auto-formats, if any
  const originalUrl = url.replace(
    new RegExp(
      `\\/f_auto,q_auto,dpr_${actualDeviceDPR("auto")},(c_fit,b_white|c_fit)`
    ),
    ""
  );

  // if image is an animated format then set the image object as video
  return getContentType(originalUrl, { keepalive: true })
    .then(contentType => {
      const ext = contentType.replace("image/", "");

      debug(
        `${url} has content-type %c${ext}`,
        "debug",
        "color: yellow; font-weight:600"
      );

      return (
        getAnimatedImageMimeTypes().indexOf(contentType.toLowerCase()) !== -1
      );
    })
    .catch(error => {
      debug(
        `${url} content-type could not be detected: %c${error.message}`,
        "debug",
        "color: red; font-weight:600"
      );
    });
};

/**
 * @description Check if the given source URL looks like a video URL
 * @param {String} src The image source URL
 * @returns {Boolean} Returns true if the image URL seems to be a video URL, false otherwise
 */
const isVideo = src =>
  [
    /^https?:\/\/.*\.((youtube|vimeo|rumble|bitchute)\.com)|youtu\.be\//,
    new RegExp(`\\.(${SUPPORTED_VIDEO_EXT.join("|")})$`)
  ].some(re => re.test(src));

/**
 * @description Calc the common image boundaries for a serie of image-aware items
 * @param {Array} items The image-aware items
 * @param {number} colspan The single item colspan
 * @param {number} [defaultAspect=1] The image default aspect (the height/width)
 * @returns {Object} Returns an object describing the common image boundaries
 */
const calcImgMaxSize = (items, colspan, defaultAspect = 1) => {
  const itemsPerSlide = breakpointItemsPerSlide(Math.ceil(12 / colspan))[
    actualDeviceBreakpoint()
  ];

  const size = imgWidthByItemsPerSlide(itemsPerSlide);

  const imgSize = items.reduce(
    (carry, item) => ({
      size: Math.max(carry.size, ...Object.values(item.sizes || {})),
      aspect: Math.max(carry.aspect, item.aspect || 0)
    }),

    { size, aspect: defaultAspect }
  );

  const maxWidth = imgSize.size || size;

  const result = {
    //maxWidth,
    maxHeight: maxWidth * imgSize.aspect,
    aspect: imgSize.aspect
  };

  result.minHeight = result.maxHeight;

  return result;
};

/**
 * @description Calculate the aspect ratio for a given image by URL
 * @param {String} imageUrl The image URL
 * @returns {Promise} Returns a promise that resolves the respective aspect ratio
 */
const aspectRatio = imageUrl =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.src = imageUrl;

    image.onload = () => {
      const aspectRatio = image.height / image.width;
      resolve(aspectRatio);
    };

    image.onerror = () => {
      reject(new Error(`Load error: ` + imageUrl));
    };
  });

/**
 * @description Get the actual device pixel ratio (DPR)
 * @param {*} defaultValue The default value when cannot detect the actual DPR
 * @return {*}
 */
const actualDeviceDPR = defaultValue =>
  "undefined" === typeof window || !window.devicePixelRatio // prevent Webpack compilation error
    ? defaultValue
    : window.devicePixelRatio.toFixed(1);

export {
  SUPPORTED_VIDEO_EXT,
  SUPPORTED_IMAGE_EXT,
  actualDeviceDPR,
  calcImgMaxSize,
  getAnimatedImageMimeTypes,
  getImageMimeType,
  getMimeFileExt,
  isAnimatedImage,
  isVideo,
  aspectRatio,
  getSliderImgSize,
  imgWidthByItemsPerSlide,
  breakpointItemsPerSlide
};
