// the content-types entries cache
const contentTypeCache = {};

// the test-url entries cache
const testUrlCache = {};

/**
 * @description Get the resource content type (via HEAD)
 * @param {String} url The url address
 * @param {Object} [options={}] The fetch options
 * @param {Boolean} [cache=false] When true cache the result
 * @returns {Promise} Returns a promise that returns the content-type header on success
 */
function getContentType(url, options = {}, cache = false) {
  if (cache && contentTypeCache[url]) {
    return Promise.resolve(contentTypeCache[url]);
  }

  const init = {
    method: "HEAD",
    redirect: "follow",
    //mode: "no-cors", // test-mode ONLY
    ...options
  };

  // https://developer.mozilla.org/en-US/docs/Web/API/fetch
  return fetch(url, init).then(response => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Response
    if (response.ok || (0 === response.status && "no-cors" === init.mode)) {
      const result = response.headers.get("content-type");

      if (cache) {
        contentTypeCache[url] = result;
      }

      return result;
    }

    return Promise.reject(
      new Error(response.statusText || `HTTP ${response.status} error`)
    );
  });
}

function testUrl(url, options = {}, cache = false) {
  if (cache && testUrlCache[url]) {
    return Promise.resolve(testUrlCache[url]);
  }

  const init = {
    method: "HEAD",
    redirect: "follow",
    //mode: "no-cors", // test-mode ONLY
    ...options
  };

  // https://developer.mozilla.org/en-US/docs/Web/API/fetch
  return fetch(url, init).then(response => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Response
    if (response.ok || (0 === response.status && "no-cors" === init.mode)) {
      const result = response.headers;

      if (cache) {
        testUrlCache[url] = result;
      }

      return result;
    }

    return Promise.reject(
      new Error(response.statusText || `HTTP ${response.status} error`)
    );
  });
}

export { getContentType, testUrl };
