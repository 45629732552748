import Media from "@components-core/Media";
import OffCanvasSidebar from "@components-core/OffCanvasSidebar";
import PureComponent from "@components-core/PureComponent";
import { connectHOCs } from "@components-utils";
import {
  PRODUCT_PAGE_SELECTORS,
  PRODUCT_SELECTOR_TYPE_BRAND,
  PRODUCT_SELECTOR_TYPE_CATEGORY,
  PRODUCT_SELECTOR_TYPE_FAVORITE,
  PRODUCT_SELECTOR_TYPE_SEARCH_RESULT,
  PRODUCT_SELECTOR_TYPE_SERIES
} from "@constants";
import GraphQLComponent from "@graphql-component";
import gqlProductCategories from "@graphql-query/productCategories.gql";
import gqlProductCategorySummaryFragment from "@graphql-query/productCategorySummaryFragment.gql";
import gqlProductImageFieldsFragment from "@graphql-query/productImageFieldsFragment.gql";
import gqlProductImageFragment from "@graphql-query/productImageFragment.gql";
import gqlProductSeries from "@graphql-query/productSeries.gql";
import gqlSearchFavorite from "@graphql-query/searchFavorite.gql";
import gqlRelatedProductFragment from "@graphql-query/relatedProductFragment.gql";
import gqlSEOScoreFragment from "@graphql-query/seoScoreFragment.gql";
import gqlTrademarks from "@graphql-query/trademarks.gql";
import HandleType from "@prop-types/HandleType";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import PositionType from "@prop-types/PositionType";
import { cartAddProduct } from "@redux-actions/cart";
import {
  applyFilterFailure,
  applyFilterSuccess,
  fetchFiltredData,
  filterApply,
  filterItemUpdate,
  filterReset
} from "@redux-actions/filters";
import { ProductCategorySelectorBS } from "@style-variables";
import { mediaBreakpoint } from "@utils/breakpoints";
import { getComponentClassName, joinNonEmptyStrings } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import MediaQuery from "react-responsive";
import ActiveResultSet from "../ProductFilter/ActiveResultSet";
import ProductFilterActiveSet from "../ProductFilter/ActiveSet";
import ProductFilter, {
  BUTTON_APPLY_FILTER,
  BUTTON_RESET_FILTER
} from "../ProductFilter/ProductFilter";
import { extractSearchParamsFilters } from "../ProductFilter/utils";
import ProductFilterResult from "./ProductCategory";

class ProductCategorySelector extends PureComponent {
  constructor(props) {
    super(props);

    this.handleCustomHandlerClick = this.handleCustomHandlerClick.bind(this);

    this.handleCallback = null;

    this.state = {
      isFetching: true,
      searchParamsFilters: extractSearchParamsFilters(props.items)
    };

    this.sidebarRef = React.createRef();

    this.unmounted = !props.placeholder;
  }

  /**
   * @description Transforms the server-provided end-component `id` property value to a nested `id` dash-separated property value
   * @param {Object} item The server-provided layout item
   * @param {String} [parentId=null] The item parent Id, if any
   * @returns {Object} The transformer layout item
   * @example before `id : "90cm"` after `id: "measurement-depth-90cm"`
   */
  nestedIdTransformer(item, parentId = null) {
    if (item.props) {
      if (item.props.id) {
        item.props.id = joinNonEmptyStrings(parentId, item.props.id);
      }

      if (item.props.items) {
        item.props.items = item.props.items.map(subItem =>
          this.nestedIdTransformer(subItem, item.props.id)
        );
      }
    } else {
      if (item.id) {
        item.id = joinNonEmptyStrings(parentId, item.id);
      }
    }

    return item;
  }

  /**
   * @description Initialize the filters
   * @memberof ProductFilter
   */
  initFilters() {
    this.props.filterReset();

    this.state.searchParamsFilters.forEach(({ id, value, title, data }) => {
      this.props.filterItemUpdate(id, value, title || data, data);
    });

    this.props.filterApply();
  }

  componentDidMount() {
    this.unmounted = false;

    this.initFilters();

    const payload = { filters: this.state.searchParamsFilters };

    switch (this.props.selectorType) {
      case PRODUCT_SELECTOR_TYPE_SEARCH_RESULT:
        payload.searchKey = this.props.searchKey;
        payload.searchOptions = this.props.searchOptions;
        break;
      case PRODUCT_SELECTOR_TYPE_FAVORITE:
        payload.filters = this.props.favoriteItems;
        break;
      default:
        payload.searchKey = this.props.match.params.categoryId;
        break;
    }

    this.props
      .fetchFiltredData(
        payload.searchKey,
        this.props.selectorType,
        payload.filters,
        payload.searchOptions,
        this.props.siteConfig
      )
      .then(data => {
        this.props.applyFilterSuccess(data);

        if (!this.unmounted) {
          this.setState({ isFetching: false });
        }
      })
      .catch(error =>
        this.props.applyFilterFailure(
          error,
          this.props.i18n.UNEXPECTED_ERROR_CAUSE.context.FILTER
        )
      );
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  /**
   * @description Dispatch the custom handler toggling event to a callback resolver
   * @param {Event} e
   * @memberof ProductCategorySelector
   */
  handleCustomHandlerClick(e) {
    if ("function" === typeof this.handleCallback) {
      this.handleCallback(e);
    }
  }

  /**
   * @description Renders a custom handler (button) for toggling the filter widget component
   * @returns {JSX}
   * @memberof ProductCategorySelector
   */
  renderCustomHandler() {
    return (
      <Container className="text-center">
        <Button
          variant="dark"
          size="lg"
          onClick={this.handleCustomHandlerClick}
        >
          {this.props.i18n.components.ProductCategorySelector.BTN_APPLY_FILTER}
        </Button>
      </Container>
    );
  }

  /**
   * @description Renders the filter widget component
   * @returns {JSX}
   * @memberof ProductCategorySelector
   */
  renderFilterWidget() {
    const buttons = [
      {
        variant: "dark",
        block: true,
        type: BUTTON_APPLY_FILTER,
        title:
          this.props.i18n.components.ProductCategorySelector.BTN_APPLY_FILTER,
        onClick: this.handleCallback
      },
      {
        className: "float-right",
        variant: "light",
        block: true,
        type: BUTTON_RESET_FILTER,
        title:
          this.props.i18n.components.ProductCategorySelector.BTN_RESET_FILTER,
        icon: { icon: "backspace" },
        onClick: this.handleCallback
      }
    ];

    return (
      <ProductFilter
        buttons={buttons}
        items={this.props.items}
        categoryId={this.props.searchKey || this.props.match.params.categoryId}
        selectorType={this.props.selectorType}
        autoFilter={this.props.autoFilter}
        searchOptions={this.props.searchOptions}
        placeholder={this.props.placeholder}
      />
    );
  }

  /**
   * @description Renders the filtred product category products
   * @returns {JSX}
   * @memberof ProductCategorySelector
   */
  renderFilterResult() {
    return (
      <React.Fragment>
        <ProductFilterActiveSet
          items={[]}
          categoryId={
            this.props.searchKey || this.props.match.params.categoryId
          }
          selectorType={this.props.selectorType}
          searchOptions={this.props.searchOptions}
          placeholder={this.props.placeholder}
          availability={{
            inStock: this.props.inStockCount,
            outOfStock: this.props.resultCount - this.props.inStockCount
          }}
        />
        <ProductFilterResult
          categoryId={
            this.props.searchKey || this.props.match.params.categoryId
          }
          colspan={Math.ceil(12 / this.props.itemsPerRow)}
          items={this.props.items}
          isFetching={this.state.isFetching}
          selectorType={this.props.selectorType}
          placeholder={this.props.placeholder}
        />
      </React.Fragment>
    );
  }

  gqlQuery(selectorType) {
    const result = [
      gqlProductImageFragment,
      gqlProductCategorySummaryFragment,
      gqlProductImageFieldsFragment,
      gqlSEOScoreFragment
    ];
    switch (selectorType) {
      case PRODUCT_SELECTOR_TYPE_SEARCH_RESULT:
      case PRODUCT_SELECTOR_TYPE_CATEGORY:
        result.unshift(gqlProductCategories);
        return result;
      case PRODUCT_SELECTOR_TYPE_BRAND:
        result.unshift(gqlTrademarks);
        return result;
      case PRODUCT_SELECTOR_TYPE_SERIES:
        result.unshift(gqlProductSeries);
        return result;
      case PRODUCT_SELECTOR_TYPE_FAVORITE:
        delete result[1];
        result.unshift(gqlSearchFavorite);
        result.push(gqlRelatedProductFragment);
        return result;
      default:
        throw new Error(
          `Unexpected value for selectorType = "${selectorType}"`
        );
    }
  }

  gqlVarName(selectorType) {
    switch (selectorType) {
      case PRODUCT_SELECTOR_TYPE_SEARCH_RESULT:
      case PRODUCT_SELECTOR_TYPE_CATEGORY:
        return "categoryId";
      case PRODUCT_SELECTOR_TYPE_BRAND:
        return "trademarkId";
      case PRODUCT_SELECTOR_TYPE_SERIES:
        return "serieId";
      default:
        throw new Error(
          `Unexpected value for selectorType = "${selectorType}"`
        );
    }
  }

  /**
   * @description Renders the product category footer text
   * @returns {JSX}
   * @memberof ProductCategorySelector
   */
  renderFooter() {
    let key;
    switch (this.props.selectorType) {
      case PRODUCT_SELECTOR_TYPE_SEARCH_RESULT:
      case PRODUCT_SELECTOR_TYPE_CATEGORY:
        key = "productCategories";
        break;
      case PRODUCT_SELECTOR_TYPE_BRAND:
        key = "trademarks";
        break;
      case PRODUCT_SELECTOR_TYPE_SERIES:
        key = "productSeries";
        break;
      case PRODUCT_SELECTOR_TYPE_FAVORITE:
        key = "searchFavorite";
        break;
      default:
        throw new Error(
          `Unexpected value for selectorType = "${this.props.selectorType}"`
        );
    }

    const gqlProps = {
      graphqlClient: this.props.graphqlClient,
      query: this.gqlQuery(this.props.selectorType),
      variables: {
        siteId: this.props.siteId
      },
      dataTransformer: data => ({
        text: (data[key][0] || {}).footer,
        imageOrientation: "left",
        img: {}
      }),
      wraps: props => (props.text ? <Media {...props} /> : null)
    };

    const categoryId = +this.props.match.params.categoryId;

    // if the given route param is an integer, search by search-id
    if (Number.isInteger(categoryId)) {
      gqlProps.variables[this.gqlVarName(this.props.selectorType)] = categoryId;
    }
    // otherwise search by search-key
    else if (this.props.match.params.categoryId) {
      gqlProps.variables.searchKey = this.props.match.params.categoryId;
    }

    return <GraphQLComponent {...gqlProps} />;
  }
  /**
   * @description Renders the component inline with the rest of page components
   * @returns {JSX}
   * @memberof ProductCategorySelector
   */
  renderInline() {
    const resultColLarge = this.props.items.length ? 9 : 12;

    const filterWidget = this.props.items.length ? (
      <Col lg="3" sm="12" xs="12" className="mx-0 px-0">
        {this.renderFilterWidget()}
      </Col>
    ) : null;

    return (
      <Container className={getComponentClassName(ProductCategorySelectorBS)}>
        <Row>
          {filterWidget}
          <Col lg={resultColLarge} sm="12" xs="12">
            {this.renderFilterResult()}
            {this.renderFooter()}
          </Col>
        </Row>
      </Container>
    );
  }

  /**
   * @description Renders the component as off-canvas-sidebar
   * @returns {JSX}
   * @memberof ProductCategorySelector
   */
  renderSidewise() {
    const handler = {
      title:
        this.props.i18n.components.ProductCategorySelector.BTN_APPLY_FILTER,
      type: HandleType.CUSTOM
    };

    /* Result: NNN products */
    const resultSet = (
      <ActiveResultSet
        className={this.props.className}
        count={this.props.resultCount}
        title={
          this.props.i18n.components.ProductCategorySelector.LABEL_FILTER_RESULT
        }
      />
    );

    return (
      <React.Fragment>
        {this.props.items.length ? this.renderCustomHandler() : null}
        {this.renderFilterResult()}
        {this.renderFooter()}
        <OffCanvasSidebar
          handler={{
            ...handler,
            onToggle: callback => {
              this.handleCallback = callback;
            }
          }}
          header={{
            title:
              this.props.i18n.components.ProductCategorySelector
                .BTN_APPLY_FILTER
          }}
          footer={resultSet}
        >
          {this.props.items.length ? this.renderFilterWidget() : null}
        </OffCanvasSidebar>
      </React.Fragment>
    );
  }

  /**
   * @description Renders the component for the given media breakpoint only
   * @param {PositionType} position One of INLINE|SIDE

   * @param {Object} breakpoint The media query breakpoint
   * @returns {JSX}
   * @memberof ProductCategorySelector
   */
  renderDevice(position, breakpoint) {
    let children = null;

    if (position === PositionType.SIDE) {
      children = this.renderSidewise();
    } else {
      children = this.renderInline();
    }

    return <MediaQuery {...breakpoint}>{children}</MediaQuery>;
  }

  /**
   * @description Renders the component while on non-desktop-like devices
   * @returns {JSX}
   * @memberof ProductCategorySelector
   */
  renderMobileDevice() {
    return this.renderDevice(PositionType.SIDE, mediaBreakpoint.mobile);
  }

  /**
   * @description Renders the component while on desktop-like devices
   * @returns {JSX}
   * @memberof ProductCategorySelector
   */
  renderDesktopDevice() {
    return this.renderDevice(PositionType.INLINE, mediaBreakpoint.default);
  }

  render() {
    if (this.unmounted || this.props.hasCompareResult) {
      return null;
    }

    return (
      <React.Fragment>
        {this.renderMobileDevice()}
        {this.renderDesktopDevice()}
      </React.Fragment>
    );
  }
}

ProductCategorySelector.propTypes = {
  ...ItemsAwareProps,
  selectorType: PropTypes.oneOf(PRODUCT_PAGE_SELECTORS),
  autoFilter: PropTypes.bool,
  placeholder: PropTypes.bool,
  itemsPerRow: PropTypes.number
};

ProductCategorySelector.defaultProps = {
  items: [],
  itemsPerRow: 3
};

// ------------------- REDUX ----------------------
ProductCategorySelector.mapDispatchToProps = {
  filterApply,
  filterReset,
  fetchFiltredData,
  applyFilterSuccess,
  applyFilterFailure,
  filterItemUpdate,
  cartAddProduct
};

ProductCategorySelector.mapStateToProps = (state, ownProps) => {
  const compareResult = state.compareProductsResult;
  const favorite = state.productFavoriteResult.items;

  const favoriteItems = Object.keys(favorite)
    .filter(productId => favorite[productId])
    .map(productId => ({
      data: `${productId}`,
      id: "id",
      value: favorite[productId]
    }));

  return {
    favoriteItems,
    resultCount: state.productFilterResult.count || 0,
    inStockCount: (state.productFilterResult.items || []).reduce(
      (carry, item) => carry + +item.availability.inStock,
      0
    ),
    hasCompareResult:
      !compareResult.isFetching &&
      !compareResult.error &&
      compareResult.items.length > 1
  };
};

export default connectHOCs(ProductCategorySelector, { withAll: true });
