import ExternalLink from "@components-core/ExternalLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RouteLinkBS } from "@style-variables";
import { applySearchParams } from "@utils/route";
import { getComponentClassName, stripHtmlTags } from "@utils/strings";
import { isExternalUrl } from "@utils/url";
import PropTypes from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";
import { getGraphqlEndpoint } from "../../sites/_utils";

const graphqlOrigin = getGraphqlEndpoint().replace(/(.*:\/\/[^/]+)\/.+/, "$1");

const RouteLink = props => {
  const url = props.to || props.href || props.url;

  if (!url) {
    return props.children;
  }

  const iconName = props.download ? "download" : null;

  const faIcon = iconName ? (
    <FontAwesomeIcon
      icon={iconName}
      className="m-1"
      style={{ fontSize: ".75rem" }}
    />
  ) : null;

  const linkProps = {
    ...props,
    title: props.title ? stripHtmlTags(props.title) : null
  };

  const content = linkProps.children || linkProps.title;

  // for absolute URLs (://) render a standard <a>
  // @todo why not a Link ?
  if (
    (!linkProps.download || !url.startsWith(graphqlOrigin)) &&
    // "_blank" === linkProps.target ||
    isExternalUrl(url)
  ) {
    delete linkProps.activeClassName;

    return (
      <ExternalLink
        {...{
          ...linkProps,
          "aria-label": linkProps.title,
          target: linkProps.download ? "_blank" : linkProps.target,
          rel:
            linkProps.download || "_blank" === linkProps.target
              ? "noreferrer"
              : linkProps.rel,
          className: [props.activeClassName, props.className]
            .filter(Boolean)
            .join(" ")
        }}
      >
        {content}
      </ExternalLink>
    );

    // return (
    //   // eslint-disable-next-line react/jsx-no-target-blank
    //   <a
    //     {...linkProps}
    //     href={applySearchParams(url)}
    //     url={null}
    //     to={null}
    //     aria-label={linkProps.title}
    //     disabled={linkProps.disabled}
    //     target={linkProps.download ? "_blank" : linkProps.target}
    //     rel={
    //       linkProps.download || "_blank" === linkProps.target
    //         ? "noreferrer"
    //         : linkProps.rel
    //     }
    //   >
    //     {linkProps.children}
    //   </a>
    // );
  }

  const separator =
    Boolean(linkProps.download ? faIcon : content) &&
    Boolean(linkProps.download ? content : faIcon)
      ? " "
      : null;

  // for internal URLs render a NavLink
  return (
    <NavLink
      {...{ ...linkProps, url: undefined }}
      className={getComponentClassName(RouteLinkBS, null, linkProps.className)}
      to={applySearchParams(url)}
      role="navigation"
      aria-label={linkProps.title || url}
      activeClassName={linkProps.activeClassName}
      disabled={linkProps.disabled}
      target={linkProps.download ? "_blank" : linkProps.target}
      rel={
        linkProps.download || "_blank" === linkProps.target
          ? "noreferrer"
          : linkProps.rel
      }
    >
      {linkProps.download ? faIcon : content}
      {separator}
      {linkProps.download ? content : faIcon}
    </NavLink>
  );
};

export default RouteLink;

RouteLink.propTypes = {
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
  url: PropTypes.string,
  title: PropTypes.string,
  enabled: PropTypes.bool,
  download: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

RouteLink.defaultProps = {
  className: "px-0 d-inline",
  activeClassName: "disabled",
  to: null,
  href: null,
  url: null,
  children: null,
  title: null
};
