import { hashValue, sortObject } from "@utils/strings";
import {
  CHECKOUT_PLACE_ORDER,
  CHECKOUT_PLACE_ORDER_DISCARD,
  CHECKOUT_PLACE_ORDER_FAILURE,
  CHECKOUT_PLACE_ORDER_REQUEST,
  CHECKOUT_PLACE_ORDER_SUCCESS
} from "../actionTypes";
import { initCart } from "./cart";
import { initCheckout } from "./checkout";
import { errorAddUnhandledException } from "./error";

// when true mangles the order's shopper name/address
const SAFEGUARD_TEST_ORDER = true;

/**
 * @description Calculate the signature for the given payload
 * @param {Object} data The payload
 * @returns {number} Returns the payload corresponding signature value
 */
const calcPayloadSignature = data => {
  // sign the data package
  const now = new Date();
  if (now.getSeconds() >= 30) {
    now.setMinutes(now.getMinutes() + 1);
  }
  now.setMilliseconds(0);
  now.setSeconds(0);

  const obj = sortObject({
    ...data,
    _timestamp: now.toISOString()
  });

  return hashValue(JSON.stringify(obj));
};

/**
 * @description Discard the exitent placed but not payed order
 * @returns {Object} The action
 */
function discardPlacedOrder() {
  return { type: CHECKOUT_PLACE_ORDER_DISCARD };
}

/**
 * @description Requesting fetching the coupon by code
 * @param {String} couponCode The coupon code
 * @returns {Object} The action
 */
function placeOrderRequest(payload) {
  return {
    type: CHECKOUT_PLACE_ORDER_REQUEST,
    payload
  };
}

/**
 * @description Updating the store with the successfully placed order
 * @param {Object} result The place order response
 * @returns {Object} The action
 */
function placeOrderSuccess(result) {
  return {
    type: CHECKOUT_PLACE_ORDER_SUCCESS,
    result
  };
}

/**
 * @description Notifying the store about failing placing the order
 * @param {Error} error
 * @returns {Object}
 */
function placeOrderFailure(error, context, unhandled = true) {
  return dispatch => {
    if (unhandled) {
      dispatch(errorAddUnhandledException(error, context));
    }

    dispatch({
      type: CHECKOUT_PLACE_ORDER_FAILURE,
      error
    });
  };
}

/**
 * @description Submits the order to the server
 * @param {Object} payload The payload resulted as a merging of order subscribers validated data.
 * @param {Object} { siteId, i18n, graphqlClient }
 * @returns {Object} The action
 */
function checkoutPlaceOrder(
  payload,
  { siteId, userId, customerId, customerNumber, i18n, graphqlClient }
) {
  return (dispatch, getState) => {
    // inform the store we received a place-order action
    dispatch({
      type: CHECKOUT_PLACE_ORDER,
      payload
    });

    // inform the store we are sending a place-order submission request
    dispatch(placeOrderRequest(payload));

    const cartItems = payload.items.map(cartItem => ({
      productId: cartItem.id,
      quantity: cartItem.quantity,
      price: cartItem.newPrice
    }));

    const paymentOption = payload.payment
      ? {
          id: parseInt(payload.payment.value),
          value: payload.payment.amount
          //currencyCode: payload.payment.currencyCode
        }
      : null;

    const deliveryOption = payload.shipment
      ? {
          id: parseInt(payload.shipment.value),
          value: payload.shipment.amount
          //currencyCode: payload.shipment.currencyCode
        }
      : null;

    const otherOptions = null;
    // {
    //   install: { id: 343, value: 343, currencyCode: "adsd" },
    //   assembly: { id: 343, value: 343, currencyCode: "adsd" },
    //   ordering: { id: 343, value: 343, currencyCode: "adsd" },
    //   extra: { id: 343, value: 343, currencyCode: "adsd" }
    // };

    const deliveryAddress = payload.deliveryAddress;
    const altInvoiceAddress = payload.altInvoiceAddress;
    const agreeBuyTerms = payload.agreeBuyTerms;
    const agreeNewsletter = payload.agreeNewsletter;
    const message = payload.comment;

    const coupons = payload.coupons.map(coupon => ({
      id: parseInt(coupon.id),
      code: coupon.code,
      value: coupon.value,
      currencyCode: coupon.currencyCode,
      unit: coupon.unit
    }));

    const ssn = payload.ssn;

    const variables = {
      siteId,
      userId,
      customerId,
      customerNumber,
      cartItems,
      paymentOption,
      deliveryOption,
      otherOptions,
      deliveryAddress,
      altInvoiceAddress,
      agreeBuyTerms,
      agreeNewsletter
    };

    if (altInvoiceAddress) {
      variables.invoiceAddress = payload.invoiceAddress;
    }

    if (message) {
      variables.message = message;
    }

    if (coupons.length) {
      variables.coupons = coupons;
    }

    if (ssn) {
      variables.ssn = ssn;
    }

    if (SAFEGUARD_TEST_ORDER && payload.payment.testEnvironment) {
      // the backend uses this info to tag the shopper name/address with TESTER-flag
      variables.testEnvironment = true;
    }

    // return a promise to the result of requested action
    return graphqlClient.gqlModule(
      import(/* webpackChunkName: "site" */ "@graphql-mutation/placeOrder.gql"),
      { ...variables, signature: calcPayloadSignature(variables) }
    );
  };
}

function postPlaceOrderCleanUp(siteConfig) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      try {
        dispatch(initCart());
        dispatch(initCheckout());
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export {
  placeOrderRequest,
  placeOrderSuccess,
  placeOrderFailure,
  checkoutPlaceOrder,
  discardPlacedOrder,
  postPlaceOrderCleanUp
};
