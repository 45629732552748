import {
  COMPARE_APPLY,
  COMPARE_APPLY_FAILURE,
  COMPARE_APPLY_ITEM_REMOVE,
  COMPARE_APPLY_REQUEST,
  COMPARE_APPLY_SUCCESS,
  COMPARE_ITEM_ADD,
  COMPARE_ITEM_REMOVE,
  COMPARE_RESET,
  COMPARE_SET
} from "../actionTypes";

const compareProducts = (state = { enabled: false, items: {} }, action) => {
  const { id, enabled } = action;

  switch (action.type) {
    case COMPARE_SET:
      return { ...state, enabled };
    case COMPARE_ITEM_ADD:
      return {
        ...state,
        items: {
          ...state.items,
          [id]: { ...state.items[id], enabled: true, changed: true }
        }
      };
    case COMPARE_ITEM_REMOVE:
      return {
        ...state,
        items: {
          ...state.items,
          [id]: { ...state.items[id], enabled: false, changed: true }
        }
      };
    case COMPARE_RESET:
      return { enabled: false, items: {} };
    case COMPARE_APPLY:
      const newState = { enabled: true };
      newState.items = Object.keys(state.items)
        .filter(key => state.items[key].enabled)
        .reduce(
          (carry, key) =>
            Object.assign(carry, {
              [key]: {
                ...state.items[key],
                active: true,
                changed: false
              }
            }),
          {}
        );

      return newState;
    default:
      return state;
  }
};

const compareProductsResult = (state, action) => {
  const newState = {
    category: null,
    items: [],
    error: null,
    selectorType: null,
    isFetching: false
  };

  switch (action.type) {
    case COMPARE_RESET:
      return { ...newState };
    case COMPARE_APPLY_REQUEST:
      return { ...newState, isFetching: true };
    case COMPARE_APPLY_SUCCESS:
      return {
        ...newState,
        category: action.category,
        items: action.products,
        isFetching: false
      };
    case COMPARE_APPLY_FAILURE:
      return { ...newState, error: action.error };
    case COMPARE_APPLY_ITEM_REMOVE:
      return {
        ...state,
        items: state.items.filter(product => product.id !== action.id)
      };
    default:
      return state || newState;
  }
};

export { compareProducts, compareProductsResult };
