import React from "react";
import BotAwareComponent from "@components-core/BotAwareComponent";
import NotificationBar from "./Bar";

class NotificationController extends BotAwareComponent {
  static POSITION_FIXED_TOP = "fixedTop";
  static POSITION_RELATIVE_TOP = "relativeTop";
  static POSITION_FIXED_BOTTOM = "fixedBottom";

  constructor(props) {
    super(props);

    this.onNotificationItemClose = this.onNotificationItemClose.bind(this);
  }
  /**
   * @description Get the fetched notifications
   * @returns {Array}
   * @memberof NotificationController
   */
  getNotifications() {
    return this.props.items.map((item, i) => (
      <NotificationBar
        {...item}
        key={item.id}
        onClose={this.onNotificationItemClose}
      />
    ));
  }

  /**
   * @description Handle the notification item close action
   * @param {Event} e The triggering event
   * @param {String} id The notification item Id
   * @memberof NotificationController
   */
  onNotificationItemClose(e, id) {
    //
  }

  render() {
    // don't render the notifications, only its passed children
    if (!this.props.enabled || this.botRendering()) {
      return null;
    }

    return this.getNotifications();
  }
}

NotificationController.MOUNT_ON_BODY = NotificationBar.MOUNT_ON_BODY;
NotificationController.MOUNT_ON_HEADER = NotificationBar.MOUNT_ON_HEADER;

NotificationController.propTypes = {
  ...BotAwareComponent.propTypes
};

export default NotificationController;
