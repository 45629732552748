import ImageOrientationProps, {
  MEDIA_ORIENTATION
} from "@prop-types/ImageOrientationProps";
import ImageProps from "@prop-types/ImageProps";
import JSXProps from "@prop-types/JSXProps";
import TitleTextProps from "@prop-types/TitleTextProps";
import { TitledParagraphBS } from "@style-variables";
import { escapeReact } from "@utils/react";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ChildRefAwareComponent from "./ChildRefAwareComponent";
import Picture from "./Picture";
import Placeholder, { withPlaceholder } from "./Placeholder";

class TitledParagraph extends ChildRefAwareComponent {
  render() {
    const titleElement = withPlaceholder(
      this.props.placeholder,
      this.props.titleAs
        ? React.createElement(this.props.titleAs, null, this.props.title)
        : this.props.title
    );

    const title = this.props.title ? (
      <Row
        className={getComponentClassName(
          TitledParagraphBS,
          "title",
          this.props.className
        )}
        key="title"
      >
        <Col>{titleElement}</Col>
      </Row>
    ) : null;

    let text = this.props.text;

    if (this.props.placeholder) {
      text = <Placeholder lines={3} />;
    } else if (text) {
      if ("string" === typeof text) {
        text = escapeReact(text);
      }
    } else {
      text = this.props.children;
    }

    const img =
      this.props.img && this.props.img.src ? (
        <Row className="text-center py-2" key="img">
          <Col>
            <Picture {...this.props.img} placeholder={this.props.placeholder} />
          </Col>
        </Row>
      ) : null;

    if (!(title || text || img)) {
      return null;
    }

    const children = [];

    if (MEDIA_ORIENTATION.top === this.props.imageOrientation) {
      children.push(img);
    }

    children.push(
      title,
      <Row
        className={getComponentClassName(
          TitledParagraphBS,
          "text",
          this.props.className
        )}
        key="text"
      >
        <Col>{text}</Col>
      </Row>
    );

    if (MEDIA_ORIENTATION.bottom === this.props.imageOrientation) {
      children.push(img);
    }

    return (
      <Container
        className={getComponentClassName(
          TitledParagraphBS,
          null,
          this.props.containerClassname || this.props.className
        )}
      >
        {children}
      </Container>
    );
  }
}

TitledParagraph.propTypes = {
  ...ChildRefAwareComponent.propTypes,
  ...TitleTextProps,
  img: PropTypes.shape(ImageProps()),
  className: PropTypes.string,
  containerClassname: PropTypes.string,
  titleAs: JSXProps(),
  imageOrientation: ImageOrientationProps()
};

TitledParagraph.defaultProps = {
  imageOrientation: MEDIA_ORIENTATION.bottom
};

export default React.memo(TitledParagraph);
